<template>
    <p class="contents sub-text">창원파티마병원 홈페이지에 방문해 주셔서 감사드립니다.</p>
    <p class="sub-text dp2">회원가입 시 개인화 맞춤 서비스를 제공받으실 수 있습니다.</p>

    <div class="login-box">
        <!-- SNS 회원가입 주석해제 시 center클레스 제거 -->
        <ul class="contents login-box__list join center">
            <li class="login-box__item">
                <div class="icon"><img src="@/assets/resource/icon/join-icon1.png" alt="이미지"></div>
                <h3 class="tit">일반 회원가입</h3>
                <p class="desc">만 14세 이상 내국인</p>
                <div class="btn-wrap center"><a href="javascript:;" class="btn blue" @click="goNext('normal')">회원가입</a></div>
            </li>
            <li class="login-box__item">
                <div class="icon"><img src="@/assets/resource/icon/join-icon2.png" alt="이미지"></div>
                <h3 class="tit">어린이 회원가입</h3>
                <p class="desc">만 14세 미만 내국인</p>
                <div class="btn-wrap center"><a href="javascript:;" class="btn blue" @click="goNext('child')">회원가입</a></div>
            </li>
            <li class="login-box__item">
                <h3 class="tit">SNS 회원가입</h3>
                <p class="desc">SNS 계정 사용자</p>
                <div class="join-sns-icon">
                    <button type="button" class="icon naver"><span id="naver_id_login"></span></button>
                    <button type="button" class="icon kakao" @click="kakaoLogin"></button>
                </div>
            </li>
        </ul>

        <div class="contents info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">유의사항</h4>
                <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap depth03">
                            <div class="depth__text">
                                <div class="depth__wrap">
                                    <div class="depth__bullet"><span class="bullet03"></span></div>
                                    <div class="depth__text">
                                        <p>어린이 회원가입은 본인인증(휴대폰 또는 아이핀)과 법적대리인(보호자)인증 후 가입이 가능합니다.</p>
                                    </div>
                                </div>
                                <div class="depth__wrap">
                                    <div class="depth__bullet"><span class="bullet03"></span></div>
                                    <div class="depth__text">
                                        <p>법정대리인 인증은 홈페이지 회원인증으로 진행합니다.</p>
                                    </div>
                                </div>
                                <div class="depth__wrap">
                                    <div class="depth__bullet"><span class="bullet03"></span></div>
                                    <div class="depth__text">
                                        <p>SNS 계정(네이버, 카카오)관련 문의 사항은 해당 홈페이지 고객센터에서 확인 하시기 바랍니다.</p>
                                    </div>
                                </div>
                                <div class="depth__wrap">
                                    <div class="depth__bullet"><span class="bullet03"></span></div>
                                    <div class="depth__text">
                                        <p class="c-red">만 14세 미만아동은 SNS 회원가입이 불가능하며 어린이 회원가입으로 진행이 가능합니다.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {

        }
    },
    methods: {
        goNext(type){
            const memType = type == 'normal' ? 'A' : type == 'child' ? 'D' : 'B'
            this.$store.commit('setMemType', memType)

            if (type === 'child' && !confirm("개인정보보호법 제22조 5항에 의거 어린이 회원가입의 경우 법정대리인(보호자)의 동의가 필요합니다.\n\n이에 따라 법정대리인(보호자)가 병원 홈페이지 회원으로 가입되어야 어린이 회원가입이 가능합니다.\n\n보호자분께서는 홈페이지 회원일 경우 아래의 확인버튼을, 회원이 아니실 경우 아래의 취소버튼을 눌러 먼저 일반인 회원가입을 진행해 주시길 바랍니다.")) return;
            this.$router.push({ path: '/member/agree.do' });
        },
        accessToken() {
            const hash = this.$route.hash;
            const result = hash.substring(hash.indexOf("access_token") + 13, hash.indexOf("&state"));
            return result
        },
        notJoin(){
            this.$router.replace({ hash: '' })
            alert('만 14세 미만은 SNS 회원가입을 이용하실 수 없습니다.');
        },
        join(data){
            this.$store.commit('setMemName', data.name);
            this.$store.commit('setMemBirth', data.birthyear + data.birthday.replace('-', ''));
            this.$store.commit('setMemGender', data.gender);
            this.$store.commit('setMemEmail', data.email);
            this.$store.commit('setMemHpNo', data.mobile);
            this.$store.commit('setMemId', data.id);
            this.$store.commit('setMemType', 'B')
            this.$store.commit('setMemSnsType', 'NA')

            this.$router.push(`/member/agree.do`);
        },
        calcAge(date){
            const year = date.slice(0,4)
            const month = date.slice(4,6)
            const day = date.slice(6)
            const today = new Date();
            const birthDate = new Date(year, month, day);

            let age = today.getFullYear() - birthDate.getFullYear();
            const m = (today.getMonth() + 1) - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }

            return age;
        },
        // 네이버
        naverApi() {
            this.axios.get('/api/naverInfo.do', {
                params: {
                    access_token: this.accessToken()
                }
            })
                .then(res => {
                    // 유저 정보
                    const data = res.data.response;
                    const userBirtyear = data.birthyear;
                    const userBirthmonth = data.birthday.split('-')[0];
                    const userBirthday = data.birthday.split('-')[1];
                    const userBirth = userBirtyear + userBirthmonth + userBirthday

                    // id 중복
                    this.axios
                        .get(this.api.member.dupId, {
                            params: {
                                memId: data.id,
                                joinType: 'sns'
                            }
                        })
                        .then(res => {
                            if (!res.data.result.status) {
                                alert('이미 가입된 정보가 있습니다.');
                                this.$router.push('/member/join.do');
                            } else {
                                // 만 14세 미만 감지
                                if (this.calcAge(userBirth) < 14) { // 만14세 미만
                                    this.notJoin()
                                } else { // 만 14세 이상
                                    this.join(data)
                                }
                            }
                        })
                })
        },
        // 카카오 login
        kakaoLogin() {
            window.Kakao.Auth.login({
                scope: 'account_email, name, phone_number', //account_email, gender, birthday, name, birthyear, phone_number
                success: this.GetMe,
            });
        },
        // 카카오 회원정보
        GetMe(authObj) {
            //console.log(authObj); // token 정보
            window.Kakao.API.request({
                url: '/v2/user/me',
                success: res => {
                    const kakao_account = res.kakao_account;
                    const userInfo = {
                        id: res.id,
                        email: kakao_account.email,
                        // birthday: kakao_account.birthyear + kakao_account.birthday,
                        // gender: kakao_account.gender == 'male' ? 'M' : 'F',
                        name: kakao_account.name
                    }
                    this.axios
                        .get(this.api.member.dupId, {
                            params: {
                                memId: userInfo.id,
                                joinType: 'sns'
                            }
                        })
                        .then(resp => {
                            if (!resp.data.result.status) { // 중복
                                alert('이미 가입된 정보가 있습니다.');
                            } else {
                                this.$store.commit('setMemName', userInfo.name);
                                this.$store.commit('setMemBirth', userInfo.birthday);
                                this.$store.commit('setMemEmail', userInfo.email);
                                this.$store.commit('setMemGender', userInfo.gender);
                                this.$store.commit('setMemId', userInfo.id);
                                this.$store.commit('setMemType', 'B')
                                this.$store.commit('setMemSnsType', 'NA')

                                this.$router.push(`/member/agree.do`);
                            }
                        })
                },
                fail: error => {
                    console.log(error);
                }
            })
        },
    },
    mounted() {
        this.$store.commit('init')

        // 네아로
        var naver_id_login = new window.naver_id_login(this.naverId, location.origin + "/member/join.do");
        var state = naver_id_login.getUniqState();
        naver_id_login.setButton("green", 1, 50);
        naver_id_login.setDomain(location.origin);
        naver_id_login.setState(state);
        naver_id_login.init_naver_id_login();
        // 네아로 접근시
        if (this.$route.hash != '') {
            this.naverApi();
            this.$router.replace('/member/join.do')
        }
    },
}
</script>