<template>
    <div class="wrap" @click="selectOn">
        <div class="map-btn"><a href="javascript:;"></a></div>
        <div class="intro">
            <div class="intro__bg intro__bg1"></div>
            <div class="intro__bg intro__bg2">
                <div></div>
            </div>
            <div class="intro__bg intro__bg3"></div>
        </div>

        <div class="main-container">
            <div class="kv">
                <div class="kv__btn"><a href="javascript:;"></a></div>

                <swiper v-if="mainBannerList.length > 0" @swiper="kvSwiperFn" @autoplayTimeLeft="onAutoplayTimeLeft" class="swiper-container" :loop="true" :modules="modules" :autoplay="{
                    delay: 2500,
                    disableOnInteraction: false,
                }" :grabCursor="true" :effect="'creative'" :creativeEffect="{
                prev: {
                    shadow: true,
                    translate: ['-30%', 0, 0],
                },
                next: {
                    translate: ['100%', 0, 0],
                },
                }" @slideChange="swiperNum" :speed="1000" :navigation="{
                    nextEl: '.kv .swiper-kv-next',
                    prevEl: '.kv .swiper-kv-prev'
                }" :pagination="{
                    el: '.kv__control-num .swiper-pagination',
                    type: 'fraction' }">

                    <swiper-slide :class="`swiper-slide slide0${idx + 1}`" v-for="(i, idx) in mainBannerList" :key="i">
                        <img :src="`/displayFile.do?name=${ i }`" alt="메인비주얼">
                    </swiper-slide>

                    <template #container-end>
                        <div class="kv__text">
                            <div class="kv__tit">
                                <p class="kv__sub-tit" v-if="this.imgidx < 3"><span style="font-weight: bold;">적정진료 · 표준의료</span>로 지역의료 선도하는</p>
                                <p class="kv__sub-tit" v-else>고객의 평생건강을 지키는 정직한 동반자</p>
                                <h3>창원파티마병원</h3>
                            </div>
                            <div class="autoplay-progress">
                                <svg viewBox="0 0 48 48" ref="progressCircle" class="autoplay-progress-img">
                                    <circle cx="24" cy="24" r="20"></circle>
                                </svg>
                                <div class="kv__control">
                                    <button type="button" class="swiper-kv-prev swiper-button-white"></button>
                                    <button type="button" class="swiper-kv-next swiper-button-white"></button>
                                    <button href="javascript:;" class="kv__control-btn" :class="{ pause: kvPause }" @click="kvAutoplayBtn">
                                        <span class="kv__control-icon play"></span>
                                        <span class="kv__control-icon pause">
                                            <span class="bar"></span>
                                            <span class="bar"></span>
                                        </span>
                                    </button>

                                    <div class="kv__control-num">
                                        <div class="swiper-pagination"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="kv__menu">
                            <div class="kv-inner">
                                <div class="kv-m-number">
                                    <div class="kv-m-number__icon"><img src="@/assets/resource/icon/kv-phon-icon.png" alt="아이콘"></div>
                                    <div class="kv-m-number__text">
                                        <span class="text">진료예약</span>
                                        <span class="num"><a href="tel:0552701000" style="color:#fff;"> 270-1000</a></span>
                                    </div>
                                </div>
                                <ul class="kv__list">
                                    <li class="kv__item">
                                        <div class="kv__box">
                                            <div class="kv__box-one"></div>
                                            <h4 class="kv__box-title">첫방문 간편예약</h4>
                                            <div class="kv__box-cont">
                                                <ul class="kv__box-list">
                                                    <li class="kv__box-item">
                                                        <label class="kv__box-input">
                                                            <input type="text" placeholder="이름" v-model="patName">
                                                            <input type="text" placeholder="전화번호" v-model="patHpNo" maxlength="11" @input="this.$commonUtils.numberOnly">
                                                        </label>
                                                    </li>
                                                    <li class="kv__box-item mb0">
                                                        <!-- <a href="javascript:;" class="kv__box-link">예약하기</a> -->
                                                        <a class="kv__box-link actionBtn3" @click="[this.$store.commit('setFirst', {name: this.patName, hpNo: this.patHpNo}), this.patName = '', this.patHpNo = '', this.$commonUtils.showPopup()]">
                                                            <span class="back">예약하기</span>
                                                            <span class="front">예약하기</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                            <p class="kv__box-cont-desc"><b>상담시간</b> 평일 08:30~16:30, 토/공휴일 휴무</p>
                                        </div>
                                    </li>
                                    <li class="kv__item">
                                        <div class="kv__box">
                                            <div class="kv__box-one"></div>
                                            <h4 class="kv__box-title">진료예약</h4>
                                            <div class="kv__box-cont">
                                                <ul class="kv__box-list">
                                                    <li class="kv__box-item">
                                                        <router-link to="/reserve/reservation.do" class="kv__box-link actionBtn3">
                                                            <span class="back">온라인 예약</span>
                                                            <span class="front">온라인 예약</span>
                                                        </router-link>
                                                    </li>
                                                    <li class="kv__box-item">
                                                        <router-link to="/hospital/reservation/HealthReserve.do" class="kv__box-link actionBtn3">
                                                            <span class="back">종합검진 예약</span>
                                                            <span class="front">종합검진 예약</span>
                                                        </router-link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="kv__item">
                                        <div class="kv__box">
                                            <div class="kv__box-one"></div>
                                            <h4 class="kv__box-title">의료진 찾기</h4>
                                            <div class="kv__box-cont">
                                                <ul class="kv__box-list">
                                                    <li class="kv__box-item">
                                                        <label class="kv__box-input">
                                                            <input type="text" placeholder="의료진 입력" v-model="searchDoctorKeyword">
                                                        </label>
                                                    </li>
                                                    <li class="kv__box-item">
                                                        <a class="kv__box-link actionBtn3" @click="searchDoctor">
                                                            <span class="back">검색</span>
                                                            <span class="front">검색</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="kv__item">
                                        <div class="kv__box info">
                                            <ul class="kv__info-list">
                                                <li class="kv__info-item">
                                                    <router-link to="/hospital/department-doctor/treatmentChart.do" class="kv__info-link">
                                                        <span class="kv__box-one2"></span>
                                                        <span class="icon"></span>
                                                        <h4>진료일정표</h4>
                                                    </router-link>
                                                </li>
                                                <li class="kv__info-item">
                                                    <router-link to="/mypage/reservation/selectReservation.do" class="kv__info-link">
                                                        <span class="kv__box-one2"></span>
                                                        <span class="icon"></span>
                                                        <h4>예약조회</h4>
                                                    </router-link>
                                                </li>
                                                <li class="kv__info-item">
                                                    <a href="/hospital/reservation/reCertification.do" class="kv__info-link">
                                                        <span class="kv__box-one2"></span>
                                                        <span class="icon"></span>
                                                        <h4>증명서 발급안내</h4>
                                                    </a>
                                                </li>
                                                <li class="kv__info-item">
                                                    <router-link to="/hospital/customer-service/voice-customer.do" class="kv__info-link">
                                                        <span class="kv__box-one2"></span>
                                                        <span class="icon"></span>
                                                        <h4>고객의 소리</h4>
                                                    </router-link>
                                                </li>

                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                            </div>


                            <button type="button" class="swiper-kv-prev swiper-button-white"></button>
                            <button type="button" class="swiper-kv-next swiper-button-white"></button>
                        </div>
                    </template>
                </swiper>


            </div>

            <div class="information">
                <div class="information__slide">
                    <swiper :slidesPerView="'auto'" :loop="true" :navigation="{
                                nextEl: '.information__slide-button-next',
                                prevEl: '.information__slide-button-prev'
                            }" :modules="modules">
                        <swiper-slide class="swiper-slide">
                            <a href="https://www.fatimahosp.co.kr/deptHp/1/healthcenter/main.do" class="slide__link" title="새창열림" target="_blank">
                                <div class="slide__icon icon1"></div>
                                <div class="slide__name">건강증진센터</div>
                            </a>
                        </swiper-slide>
                        <swiper-slide class="swiper-slide">
                            <a href="https://www.fatimahosp.co.kr/deptHp/2/onecenter/main.do" class="slide__link" title="새창열림" target="_blank">
                                <div class="slide__icon icon6"></div>
                                <div class="slide__name">직업환경의학센터(국가건강검진)</div>
                            </a>
                        </swiper-slide>
                        <swiper-slide class="swiper-slide">
                            <a href="https://www.fatimahosp.co.kr/deptHp/3/medicalcenter/main.do" class="slide__link" title="새창열림" target="_blank">
                                <div class="slide__icon icon2"></div>
                                <div class="slide__name">진료협력센터</div>
                            </a>
                        </swiper-slide>
                        <swiper-slide class="swiper-slide">
                            <a href="/healthinfo/diseaseList.do" class="slide__link">
                                <div class="slide__icon icon3"></div>
                                <div class="slide__name">건강질병정보</div>
                            </a>
                        </swiper-slide>
                        <swiper-slide class="swiper-slide">
                            <a href="/healthinfo/inspecList.do" class="slide__link">
                                <div class="slide__icon icon4"></div>
                                <div class="slide__name">검사/처치정보</div>
                            </a>
                        </swiper-slide>
                        <swiper-slide class="swiper-slide">
                            <a href="/hospital/department-doctor/HospiceCare.do" class="slide__link">
                                <div class="slide__icon icon5"></div>
                                <div class="slide__name">호스피스 완화의료</div>
                            </a>
                        </swiper-slide>

                        <swiper-slide class="swiper-slide">
                            <a href="https://fatimahosp.egentouch.com/main.do?action=main" class="slide__link" title="새창열림" target="_blank">
                                <div class="slide__icon icon7"></div>
                                <div class="slide__name">의학도서실</div>
                            </a>
                        </swiper-slide>
                        <swiper-slide class="swiper-slide">
                            <a href="https://hwa.hiwebnet.com/?mode=redir&code=HQR_0002&yyk_sq=3942" target="_blank" class="slide__link">
                                <div class="slide__icon icon8"></div>
                                <div class="slide__name">실손보험 청구 간소화 서비스</div>
                            </a>
                        </swiper-slide>
                    </swiper>
                    <button type="button" class="information__slide-button-prev"></button>
                    <button type="button" class="information__slide-button-next"></button>
                </div>
                <div class="information__box-mo">
                    <div class="main-inner">
                        <ul class="information__list-mo">
                            <li class="information__item-mo">
                                <a href="https://www.fatimahosp.co.kr/deptHp/1/healthcenter/main.do">
                                    건강증진센터
                                    <span class="icon link"></span>
                                </a>
                            </li>
                            <li class="information__item-mo">
                                <a href="https://www.fatimahosp.co.kr/deptHp/2/onecenter/main.do">
                                    직업환경의학센터<br>(국가건강검진)
                                    <span class="icon link"></span>
                                </a>
                            </li>
                            <li class="information__item-mo">
                                <a href="https://www.fatimahosp.co.kr/deptHp/3/medicalcenter/main.do">
                                    진료협력센터
                                    <span class="icon link"></span>
                                </a>
                            </li>
                            
                            <li class="information__item-mo">
                                <a href="/healthinfo/diseaseList.do">건강질병정보
                                    <span class="icon"></span>
                                </a>
                            </li>
                            <li class="information__item-mo">
                                <a href="/healthinfo/inspecList.do">검사처치 정보
                                    <span class="icon"></span>
                                </a>
                            </li>
                            <li class="information__item-mo">
                                <a href="/hospital/department-doctor/HospiceCare.do">호스피스완화의료
                                    <span class="icon"></span>
                                </a>
                            </li>
                            <li class="information__item-mo">
                                <a href="https://fatimahosp.egentouch.com/main.do?action=main">의학도서실
                                    <span class="icon"></span>
                                </a>
                            </li>
                            <li class="information__item-mo">
                                <a href="https://hwa.hiwebnet.com/?mode=redir&code=HQR_0002&yyk_sq=3942" target="_blank">실손보험 청구 간소화 서비스
                                    <span class="icon"></span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="information__wrap">
                    <div class="information__box information__box1">
                        <div class="information__box-bg"></div>
                        <h3 class="information__tit information__tit1">진료안내</h3>
                        <p class="information__desc information__desc1">방문하실 진료과/센터/클리닉을 선택하면 바로 이동됩니다</p>
                        <ul class="information__list select">
                            <li class="information__item information__item1" :class="{on : selectOn1}" @click="selectBoxBtn($event, 1)">
                                <div class="select-box">
                                    <div class="icon"></div>
                                    <button type="button" class="select-box__btn">진료과를 선택해주세요</button>
                                    <ul class="select-box__list">
                                        <li class="select-box__item" v-for="(obj, idx) in departmentList" :key="idx">
                                            <router-link :to="`/hospital/department-doctor/departmentDetail.do?deptCd=${obj.deptCd}`" class="select-box__link">{{ obj.deptNm }}</router-link>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li class="information__item information__item1" :class="{on : selectOn2}" @click="selectBoxBtn($event, 2)">
                                <div class="select-box">
                                    <div class="icon"></div>
                                    <button type="button" class="select-box__btn">센터를 선택해주세요</button>
                                    <ul class="select-box__list">
                                        <li class="select-box__item" v-for="(obj, idx) in centerList" :key="idx">
                                            <router-link :to="`/deptHp/${obj.deptCd}/${obj.hpUrl}/main.do`" class="select-box__link" v-if="obj.hpUseYn === 'Y'" target="_blank">{{ obj.deptNm }}</router-link>
                                            <router-link :to="`/hospital/department-doctor/centerClinicDetail.do?deptCd=${obj.deptCd}`" class="select-box__link" v-else>{{ obj.deptNm }}</router-link>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li class="information__item information__item1" :class="{on : selectOn3}" @click="selectBoxBtn($event, 3)">
                                <div class="select-box">
                                    <div class="icon"></div>
                                    <button type="button" class="select-box__btn">클리닉을 선택해주세요</button>
                                    <ul class="select-box__list">
                                        <li class="select-box__item" v-for="(obj, idx) in clinicList" :key="idx">
                                            <router-link :to="`/hospital/department-doctor/centerClinicDetail.do?deptCd=${obj.deptCd}`" class="select-box__link" >{{ obj.deptNm }}</router-link>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                        </ul>

                    </div>
                    <div class="information__box information__box2">
                        <h3 class="information__tit information__tit2">이용안내</h3>
                        <p class="information__desc information__desc2">병원 및 편의시설 이용 시 필요한 내용을 안내드립니다</p>
                        <ul class="information__list link">
                            <li class="information__item information__item1">
                                <div class="information_bg"></div>
                                <div class="information__item-wrap">
                                    <div class="information__item-inner">
                                        <router-link to="/hospital/information/funeral.do" class="information__link">
                                            <div class="icon icon05"></div>
                                            <span>장례식장</span>
                                        </router-link>
                                    </div>
                                </div>
                            </li>
                            <li class="information__item information__item2">
                                <div class="information_bg"></div>
                                <div class="information__item-wrap">
                                    <div class="information__item-inner">
                                        <router-link to="/hospital/information/phone.do" class="information__link">
                                            <div class="icon icon03"></div>
                                            <span>전화번호</span>
                                        </router-link>
                                    </div>
                                </div>
                            </li>
                            <li class="information__item information__item3">
                                <div class="information_bg"></div>
                                <div class="information__item-wrap">
                                    <div class="information__item-inner">
                                        <router-link to="/hospital/information/hospMap.do" class="information__link">
                                            <div class="icon icon04"></div>
                                            <span>원내배치도</span>
                                        </router-link>
                                    </div>
                                </div>
                            </li>
                            <li class="information__item information__item4">
                                <div class="information_bg"></div>
                                <div class="information__item-wrap">
                                    <div class="information__item-inner">
                                        <router-link to="/hospital/information/convenience.do" class="information__link">
                                            <div class="icon icon01"></div>
                                            <span>편의시설</span>
                                        </router-link>
                                    </div>
                                </div>
                            </li>
                            <li class="information__item information__item5">
                                <div class="information_bg"></div>
                                <div class="information__item-wrap">
                                    <div class="information__item-inner">
                                        <router-link to="/hospital/information/parking.do" class="information__link">
                                            <div class="icon icon02"></div>
                                            <span>주차</span>
                                        </router-link>
                                    </div>
                                </div>
                            </li>
                            <li class="information__item information__item6">
                                <div class="information_bg"></div>
                                <div class="information__item-wrap">
                                    <div class="information__item-inner">
                                        <a href="https://recruit.fatimahosp.co.kr" class="information__link" target="_blank">
                                            <div class="icon icon06"></div>
                                            <span>채용정보</span>
                                        </a>
                                    </div>
                                </div>
                            </li>

                        </ul>
                    </div>

                </div>
            </div>

            <!-- Search -->
            <div class="search sec-search">
                <div class="sec-wrap__inner">
                    <h3 class="sec-wrap__tit">무엇이 궁금하신가요?</h3>
                    <div class="search__inner">
                        <div class="search__box">
                            <label class="search__input">
                                <input type="text" placeholder="검색어를 입력해주세요" v-model="searchKeyword" @keyup.enter="search(searchKeyword)" >
                                <img src="@/assets/resource/img/fati.png" alt="이미지" >
                            </label>
                            <div class="search__icon">
                                <button type="submit" @click="search(searchKeyword)"></button>
                            </div>
                        </div>
                        <ul class="search__list">
                            <li class="search__item" v-for="(hashtag, index) in hashTags" :key="index" >
                                <a href="javascript:;" class="search__link" @click="search(hashtag.codeName)">#{{hashtag.codeName}}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <!-- story -->
            <div class="story">
                <div class="sec-wrap__inner type02">
                    <h3 class="sec-wrap__tit">파티마 이야기</h3>
                    <ul class="sec-wrap__list">
                        <!-- active : 클릭 시 -->
                        <li class="sec-wrap__item" :class="{'active' : this.secBtnName =='all'}" @click="secBtn('all')">
                            <button type="button">전체보기</button>
                        </li>
                        <li class="sec-wrap__item" :class="{'active' : this.secBtnName =='news'}" @click="secBtn('news')">
                            <button type="button">파티마뉴스</button>
                        </li>
                        <li class="sec-wrap__item" :class="{'active' : this.secBtnName =='press'}" @click="secBtn('press')">
                            <button type="button">언론보도</button>
                        </li>
                        <li class="sec-wrap__item" :class="{'active' : this.secBtnName =='event'}" @click="secBtn('event')">
                            <button type="button">행사안내</button>
                        </li>
                        <!--<li class="sec-wrap__item" :class="{'active' : this.secBtnName =='job'}" @click="secBtn('job')">
                            <button type="button">채용정보</button>
                        </li>-->
                        <li class="sec-wrap__item" :class="{'active' : this.secBtnName =='gallery'}" @click="secBtn('gallery')">
                            <button type="button">파티마갤러리</button>
                        </li>
                    </ul>

                    <!-- back -->
                    <div class="story__list">
                        <swiper :slidesPerView="'auto'" :loop="true" :modules="modules" :spaceBetween="20">
                            <swiper-slide :class="`story__item ${convertNoToName(i.boardNo)[0]}`" v-for="(i, idx) in mainList[secBtnName]" :key="idx">
                                <div class="border-wrap">
                                <div class="information_bg"></div>
                                </div>

                                <component :is="i.linkUrl.startsWith('http') ? 'a' : 'router-link'" :href="i.linkUrl" :to="`/news/view.do?boardNo=1&articleNo=${i.articleNo}`" :target="i.boardId == 'press' ? i.linkMethod : '_self'" class="story__inner">
                                <!-- <router-link :to="`/news/view.do?boardNo=1&articleNo=${i.articleNo}`" href="javascript:;" class="story__inner" > -->
                                    <div :class="`story__type ${convertNoToName(i.boardNo)[0]}`">{{i.menuUrl}}{{ convertNoToName(i.boardNo)[1] }}</div>
                                    <img v-if="!(convertNoToName(i.boardNo)[0] == 'all' || convertNoToName(i.boardNo)[0] == 'press') && getContentImg(i)" :src="getContentImg(i)" class="story_img" alt="이미지">
                                    <img v-else-if="!(convertNoToName(i.boardNo)[0] == 'all' || convertNoToName(i.boardNo)[0] == 'press') && i.attachBag && i.attachBag.attach" :src="`/displayFile.do?name=${i.attachBag.attach[0].savedName}`" class="story_img" alt="이미지">
                                    <div class="story__box">
                                        <div class="story__text">
                                            <h4 class="story__tit">{{i.title}}</h4>
                                            <p class="story__desc" v-html="getText(i.content)"></p>
                                        </div>
                                        <div class="story__info">
                                            <span class="story__date">{{$commonUtils.dateToStr(new Date(i.createdDt), '-')}}</span>
                                            <div class="story__link">VIEW MORE +</div>
                                        </div>
                                    </div>
                                <!-- </router-link> -->
                                </component>
                            </swiper-slide>
                        </swiper>
                    </div>
                </div>
                <div class="story__bg-text story__bg-text01">FATIMA</div>
                <div class="story__bg-text story__bg-text02">HOSPITAL</div>
            </div>

            <!-- compliment -->
            <div class="compliment">
                <div class="sec-wrap__inner type02">
                    <div class="compliment__top">
                        <h3 class="sec-wrap__tit">칭찬합니다</h3>
                        <p class="sec-wrap__desc">창원파티마병원 의료진을 칭찬합니다.</p>
                        <div class="btn-wrap type01">
                            <router-link to="/praise/list.do" >더보기 +</router-link>
                        </div>
                    </div>

                    <ul class="compliment__list">
                        <swiper :slidesPerView="'auto'" :loop="true" :modules="modules" :breakpoints="{ 360: { spaceBetween: 20 }, 721: {spaceBetween: 35 } }">
                            <swiper-slide class="compliment__item compliment__list-box" v-for="(banner, idx) in thanksBannerList" :key="idx">
                                <div class="border-wrap">
                                    <div class="information_bg"></div>
                                </div>
                                <component :is="isExternal(banner.link) ? 'a' : 'router-link'" :href="banner.link" :to="isExternal(banner.link) ? null : banner.link" class="compliment__link" :target="banner.target">
                                    <span class="compliment__list-box__img">
                                        <span class="compliment__img">
                                            <img :src="`/displayFile.do?attachNo=${banner.attachBag.bannerImg[0].attachNo}`" alt="사진">
                                            <div class="effect">
                                                <div class="crown"></div>
                                                <div class="item item1"></div>
                                                <div class="item item2"></div>
                                                <div class="item item3"></div>
                                                <div class="item item4"></div>
                                                <div class="item item5"></div>
                                                <div class="item item6"></div>
                                                <div class="item item7"></div>
                                                <div class="item item8"></div>
                                                <div class="item item9"></div>
                                                <div class="item item10"></div>
                                            </div>
                                        </span>
                                    </span>
                                    <span class="compliment__text">
                                        <span class="tit">{{ banner.title }} </span>
                                        <span class="desc">{{ banner.bannerDesc }} 과장님</span>
                                        <br>
                                        <span class="mo-n"> 감사합니다</span>
                                    </span>
                                </component>
                            </swiper-slide>
                        </swiper>
                    </ul>
                </div>
            </div>

            <div class="sns">
                <div class="sns__text">
                    <div class="main-inner">
                        <h3 class="sns__tit">파티마SNS</h3>
                        <p class="sns__desc">#창원파티마병원</p>
                        <ul class="icon__list">
                            <li class="icon__item icon__item1"><a href="https://pf.kakao.com/_ziQxad" class="icon__link" target="_blank" title="카카오 새창열림"></a></li>
                            <li class="icon__item icon__item2"><a href="https://www.facebook.com/fatimahosp/" class="icon__link" target="_blank" title="페이스북 새창열림"></a></li>
                            <li class="icon__item icon__item3"><a href="https://www.youtube.com/channel/UC_FtWRZaIgWaqQ79Nz3IRNA?sub_confirmation=1" class="icon__link" target="_blank" title="유튜브 새창열림"></a></li>
                            <li class="icon__item icon__item4"><a href="https://blog.naver.com/fatimablog" class="icon__link" target="_blank" title="블로그 새창열림"></a></li>
                        </ul>
                        <div class="sns__slide-btn">
                            <button type="button" class="swiper-button-prev swiper-button-white"></button>
                            <button type="button" class="swiper-button-next swiper-button-white"></button>
                        </div>
                    </div>
                </div>
                <div class="sns__slide">
                    <swiper ref="snsSwiper" v-if="delayedNavigation" :slidesPerView="'auto'" :breakpoints="{ 
                        360: { spaceBetween: 15 }, 
                        721: { spaceBetween: 30 } 
                    }" :loop="true" :navigation="delayedNavigation" :modules="modules">
                        <swiper-slide class="swiper-slide" v-for="(banner, idx) in snsBannerList" :key="idx">
                            <component :is="isExternal(banner.link) ? 'a' : 'router-link'" :href="banner.link" :to="isExternal(banner.link) ? null : banner.link" :target="banner.target">
                                <div class="img-wrap">
                                    <img :src="`/displayFile.do?attachNo=${banner.attachBag.bannerImg[0].attachNo}`" alt="이미지">
                                </div>
                                <p class="data">{{ banner.option1 }}</p>
                                <h4 class="title">{{ banner.title }}</h4>
                            </component>
                        </swiper-slide>
                    </swiper>
                </div>
                <!-- <div class="sns__slide-btn show">
                    <button type="button" class="swiper-button-prev swiper-button-white"></button>
                    <button type="button" class="swiper-button-next swiper-button-white"></button>
                </div> -->
            </div>

            <div class="social sec-wrap">
                <div class="social__bg"></div>
                <div class="sec-wrap__inner">
                    <h3 class="sec-wrap__tit">사회공헌활동</h3>
                    <p class="sec-wrap__desc">파티마병원은 지역사회를 위한 <br> 다양한 활동을하고 있습니다.</p>
                    <ul class="social__list">
                        <li class="social__item social__item1">
                            <router-link to="/hospital/hospitalIntro/active/loveFund.do" class="social__link">
                                <!-- <div class="circle circle01"></div> -->
                                <div class="main-circle">
                                    <div class="main-circle__bg"></div>
                                    <div class="icon"></div>
                                    <div class="tit">파티마사랑기금</div>
                                </div>
                                <!-- <div class="circle circle02"></div> -->
                            </router-link>
                        </li>
                        <li class="social__item social__item2">
                            <router-link to="/hospital/hospitalIntro/active/AllLife.do" class="social__link">
                                <div class="main-circle">
                                    <div class="main-circle__bg"></div>
                                    <div class="icon"></div>
                                    <div class="tit">온생명 Care</div>
                                </div>
                                <!-- <div class="circle circle02-1"></div> -->
                            </router-link>
                        </li>
                        <li class="social__item social__item3">
                            <router-link to="/hospital/hospitalIntro/active/volunteer.do" class="social__link">
                                <!-- <div class="circle circle03"></div> -->
                                <div class="main-circle">
                                    <div class="main-circle__bg"></div>
                                    <div class="icon"></div>
                                    <div class="tit">자원봉사활동</div>
                                </div>
                            </router-link>
                        </li>
                        <li class="social__item social__item4">
                            <router-link to="/hospital/hospitalIntro/active/cultural.do" class="social__link">
                                <!-- <div class="circle circle04"></div> -->
                                <div class="main-circle">
                                    <div class="main-circle__bg"></div>
                                    <div class="icon"></div>
                                    <div class="tit">문화활동</div>
                                </div>
                                <!-- <div class="circle circle05"></div> -->
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>


        </div>
    </div>
</template>


<script>
    // import gsap from 'gsap'
    import { ref } from 'vue';
    import { Swiper, SwiperSlide } from 'swiper/vue';
    import 'swiper/css';
    import 'swiper/css/effect-creative';
    import 'swiper/css/navigation';
    import 'swiper/css/pagination';
    import { Navigation, Autoplay, Pagination, EffectCreative } from 'swiper/modules';
    // import { gsap, ScrollTrigger, Draggable, MotionPathPlugin, Power1 } from "gsap/all";


    export default {
        components: {
            Swiper,
            SwiperSlide,
        },
        data() {
            return {
                kvPause: false,
                kvSwiper: null,
                selectOn1: false,
                selectOn2: false,
                selectOn3: false,
                secBtnName: 'all',
                slideDown: false,

                mainList: '',

                patName: '',
                patHpNo: '',

                searchDoctorKeyword: '',
                departmentList: [],
                centerList: [],
                clinicList: [],

                hashTags: [],
                searchKeyword: '',

                thanksBannerList: [],
                snsBannerList: [],
                mainBannerList: [],

                delayedNavigation: null, 

                imgidx: 0,
            }

        },
        setup() {
            const progressCircle = ref(null);
            // const progressContent = ref(null);
            const onAutoplayTimeLeft = (s, time, progress) => {
                progressCircle.value.style.setProperty('--progress', 1 - progress);
                // progressContent.value.textContent = `${Math.ceil(time / 1000)}s`;
            };
            return {
                onAutoplayTimeLeft,
                progressCircle,
                // progressContent,
                modules: [Navigation, Autoplay, Pagination, EffectCreative],
            };
        },
        methods: {
            getContentImg(article) {
                var html = article.content

                if (!html) return '';

                const regex = /<img.*?src="(.*?)"/;
                const result = regex.exec(html);

                return result ? result[1] : '';
            },
            kvSwiperFn(swiper) {
                this.kvSwiper = swiper;
            },
            kvAutoplayBtn() {
                this.kvPause = !this.kvPause;

                if (this.kvPause) {
                    this.kvSwiper.autoplay.stop();
                } else {
                    this.kvSwiper.autoplay.start();
                }
            },
            selectOn() {
                this.selectOn1 = false
                this.selectOn2 = false
                this.selectOn3 = false
            },
            selectBoxBtn(event, num) {

                this.slideDown = !this.slideDown
                // 초기화
                this.selectOn()

                const liElement = event.currentTarget.closest('.information__item');
                const hasOnClass = liElement.classList.contains('on');
                if (!hasOnClass) {
                    this['selectOn' + num] = !this['selectOn' + num];
                }
                event.stopPropagation();
            },

            secBtn(el) {
                this.secBtnName = el
            },
            swiperNum() {
                let swiperPaginationCurrent = document.querySelector(".swiper-pagination-current");
                let swiperPaginationTotal = document.querySelector(".swiper-pagination-total");
                if (swiperPaginationCurrent && swiperPaginationTotal) {
                    let current = parseInt(swiperPaginationCurrent.textContent);
                    let total = parseInt(swiperPaginationTotal.textContent);
                    if (current < 10 && total < 10) {
                        swiperPaginationCurrent.textContent = "0" + swiperPaginationCurrent.textContent;
                        swiperPaginationTotal.textContent = "0" + swiperPaginationTotal.textContent;
                    }
                    this.imgidx = Number(swiperPaginationCurrent.textContent);
                }
            },
            kvMenuList() {
                let kvBoxes = document.querySelectorAll('.kv__box');
                let autoplayProgress = document.querySelector('.autoplay-progress');

                kvBoxes.forEach(function (kvBox) {
                    kvBox.addEventListener('click', function (event) {
                        // input 요소에 클릭한 경우는 active 상태를 유지
                        if (event.target.tagName === 'INPUT') {
                            return;
                        }

                        if (this.classList.contains('active')) {
                            this.classList.remove('active');
                            autoplayProgress.classList.remove('active');
                        } else {
                            this.classList.add('active');
                            autoplayProgress.classList.add('active');

                            kvBoxes.forEach(function (box) {
                                if (box !== kvBox) {
                                    box.classList.remove('active');
                                }
                            });
                        }
                    });
                });

                // 외부 영역 클릭 시 닫히게
                document.addEventListener("click", function (event) {
                    const activeKvBox = document.querySelector(".kv__box.active");

                    // 클릭한 요소가 active 상태인 kv__box 내부에 있으면 외부로 인식하지 않음
                    if (activeKvBox && activeKvBox.contains(event.target)) {
                        return;
                    }

                    // 클릭한 요소가 kv__box 외부일 경우 active 상태 해제
                    if (!event.target.closest(".kv__box")) {
                        kvBoxes.forEach(function (conElement) {
                            conElement.classList.remove("active");
                        });
                        autoplayProgress.classList.remove('active');
                    }
                });

                // 터치 이벤트 추가 (모바일 지원)
                document.addEventListener("touchstart", function (event) {
                    const activeKvBox = document.querySelector(".kv__box.active");

                    if (activeKvBox && activeKvBox.contains(event.target)) {
                        return;
                    }

                    if (!event.target.closest(".kv__box")) {
                        kvBoxes.forEach(function (conElement) {
                            conElement.classList.remove("active");
                        });
                        autoplayProgress.classList.remove('active');
                    }
                });
            },
            getMainBoardList() {
                const param = {
                    pageRow: 6
                }
                this.axios.get(this.api.article.main, {
                    params: param
                })
                    .then(res => {
                        this.mainList = res.data
                    })
            },
            convertNoToName(no) {
                var name
                if (no == 1) {
                    name = ['news', '파티마뉴스']
                } else if (no == 10) {
                    name = ['press', '언론보도']
                } else if (no == 8) {
                    name = ['event', '행사안내']
                } else if (no == 3) {
                    name = ['job', '채용정보']
                } else {
                    name = ['gallery', '파티마갤러리']
                }
                return name
            },
            getText(html) {
                const div = document.createElement('div');
                div.innerHTML = html;
                return div.textContent || div.innerText || "";
            },
            searchDoctor(){
                this.$router.push('/hospital/department-doctor/doctor.do?drName=' + this.searchDoctorKeyword)
            },
            getDepartmentList() {
                this.axios.get(this.api.department.list, {
                    params: {
                        instNo: 1,
                        startIndex: 1,
                        langType: 'kr'
                    }
                })
                .then(res => {
                    for(let dept in res.data.deptList){
                        if(res.data.deptList[dept].deptClsf == 'B'){
                            this.clinicList.push(res.data.deptList[dept])
                        }else if(res.data.deptList[dept].deptClsf == 'A'){
                            this.departmentList.push(res.data.deptList[dept])
                        }else if(res.data.deptList[dept].deptClsf == 'C'){
                            this.centerList.push(res.data.deptList[dept])
                        }
                    }

                })
            },
            search(keyword){
                this.$router.push('/search.do?searchKeyword=' + keyword)
            },
            getHashTags(){
                this.axios.get(this.api.search.hashKeywords).then(res => {
                    this.hashTags = res.data.result
                })
            },
            goArticle(article){
                console.log(article)
            },
            getThanksBanner() {
                const param = {
                    instNo: 1,
                    bannerClsf: 'BA0000004'
                }
                this.axios.get(this.api.banner.list, {
                    params: param
                }).then(res => {
                    this.thanksBannerList = res.data
                })
            },
            getSnsBanner() {
                const param = {
                    instNo: 1,
                    bannerClsf: 'BA0000005'
                }
                this.axios.get(this.api.banner.list, {
                    params: param
                }).then(res => {
                    this.snsBannerList = res.data
                })
            },
            getMainBanner() {
                const param = {
                    instNo: 1,
                    bannerClsf: window.innerWidth > 720 ? 'A' : 'D'
                }
                this.axios.get(this.api.banner.list, {
                    params: param
                }).then(res => {
                    const list = res.data;//.sort((a, b) => a.bannerNo - b.bannerNo)
                    this.mainBannerList = []
                    for(let i of list){
                        this.mainBannerList.push(i.attachBag.bannerImg[0].savedName)
                    }
                    this.$nextTick(()=>{
                        // 스와이퍼 0 넣기
                        const width3 = window.matchMedia(`(min-width: 1000px)`); // PC
                        const widthMo = window.matchMedia(`(max-width: 720px)`); //모바일
                        if (widthMo.matches == true) {
                            this.kvMenuList()
                        }
                        if (width3.matches == true) {
                            
                            // intro
                            gsap.to('.intro__bg1', { height: 0, delay: 1 });
                            gsap.from('.intro__bg2', { height: 150 + 'vh', delay: 1.2, duration: 0.5, ease: Power1.easeInOut });
                            gsap.from('.intro__bg2 div', { height: 150 + 'vh', borderTopLeftRadius: '50%', borderTopRightRadius: '50%', delay: 1.2, duration: 0.5, ease: Power1.easeInOut });
                            gsap.to('.intro__bg3', { height: 0, borderRadius: 0, delay: 1, duration: 1, ease: Power1.easeInOut });
                            gsap.to('.intro__bg3', { borderRadius: 0, delay: 1.5, duration: 1, ease: Power1.easeInOut });
                            gsap.to('.intro', { height: 0, delay: 1.5 });
                            // intro

                            // information
                            gsap.registerPlugin(ScrollTrigger);
                            gsap.from('.information__slide', { scrollTrigger: { trigger: '.information', start: '-40% center', end: '-500' + '%', scrub: 5 }, x: 100, opacity: 0 });
                            gsap.from('.information__box-bg', { scrollTrigger: { trigger: '.information', start: '-30% center', end: '-500' + '%', scrub: 5 }, width: '100%' });
                            gsap.from('.information__tit1', { scrollTrigger: { trigger: '.information', start: '-20% center', end: '-500' + '%', scrub: 5 }, y: 40, opacity: 0 });
                            gsap.from('.information__desc1', { scrollTrigger: { trigger: '.information', start: '-20% center', end: '-500' + '%', scrub: 6 }, y: 40, opacity: 0 });
                            gsap.from('.information__list.select .information__item1', { scrollTrigger: { trigger: '.information', start: 'top center', end: '-1000' + '%', scrub: 5 }, x: -100, opacity: 0 });
                            gsap.from('.information__list.select .information__item2', { scrollTrigger: { trigger: '.information', start: 'top center', end: '-2000' + '%', scrub: 5 }, x: -100, opacity: 0 });
                            gsap.from('.information__list.select .information__item3', { scrollTrigger: { trigger: '.information', start: 'top center', end: '-3000' + '%', scrub: 5 }, x: -100, opacity: 0 });
                            gsap.from('.information__tit2', { scrollTrigger: { trigger: '.information', start: '-20% center', end: '-1000' + '%', scrub: 1 }, y: 40, opacity: 0 });
                            gsap.from('.information__desc2', { scrollTrigger: { trigger: '.information', start: '-20% center', end: '-1000' + '%', scrub: 3 }, y: 40, opacity: 0, delay: 1 });
                            gsap.from('.information__list.link .information__item1', { scrollTrigger: { trigger: '.information', start: 'top center', end: '-2000' + '%', scrub: 1 }, y: '50%', x: '50%', opacity: 0 });
                            gsap.from('.information__list.link .information__item2', { scrollTrigger: { trigger: '.information', start: 'top center', end: '-2000' + '%', scrub: 1 }, y: '50%', x: '0%', opacity: 0 });
                            gsap.from('.information__list.link .information__item3', { scrollTrigger: { trigger: '.information', start: 'top center', end: '-2000' + '%', scrub: 1 }, y: '50%', x: '-50%', opacity: 0 });
                            gsap.from('.information__list.link .information__item4', { scrollTrigger: { trigger: '.information', start: 'top center', end: '-2000' + '%', scrub: 1 }, y: '-50%', x: '50%', opacity: 0 });
                            gsap.from('.information__list.link .information__item5', { scrollTrigger: { trigger: '.information', start: 'top center', end: '-2000' + '%', scrub: 1 }, y: '-50%', x: '0%', opacity: 0 });
                            gsap.from('.information__list.link .information__item6', { scrollTrigger: { trigger: '.information', start: 'top center', end: '-2000' + '%', scrub: 1 }, y: '-50%', x: '-50%', opacity: 0 });


                            // 검색
                            gsap.from('.sec-search .sec-wrap__tit', { scrollTrigger: { trigger: '.sec-search', start: 'top center', end: '-1000' + '%', scrub: 5 }, y: 40, opacity: 0 });
                            gsap.from('.sec-search .search__inner', { scrollTrigger: { trigger: '.sec-search', start: 'top center', end: '-1000' + '%', scrub: 5 }, y: 40, opacity: 0 });

                            // 이야기
                            gsap.from('.story .sec-wrap__tit', { scrollTrigger: { trigger: '.story', start: 'top center', end: '-1000' + '%', scrub: 5 }, y: 40, opacity: 0 });
                            gsap.from('.story .sec-wrap__list', { scrollTrigger: { trigger: '.story', start: '10% center', end: '-1000' + '%', scrub: 6 }, y: 60, opacity: 0 });
                            gsap.from('.story .story__list', { scrollTrigger: { trigger: '.story', start: '10% center', end: '-1000' + '%', scrub: 6 }, x: 200, opacity: 0 });

                            // 칭찬
                            gsap.from('.compliment .sec-wrap__tit', { scrollTrigger: { trigger: '.compliment', start: 'top center', end: '-1000' + '%', scrub: 5 }, y: 40, opacity: 0 });
                            gsap.from(['.compliment .sec-wrap__desc', '.compliment .btn-wrap.type01'], { scrollTrigger: { trigger: '.compliment', start: '10% center', end: '-1000' + '%', scrub: 6 }, y: 60, opacity: 0 });
                            gsap.from('.compliment .compliment__list', { scrollTrigger: { trigger: '.compliment', start: '10% center', end: '-1000' + '%', scrub: 6 }, x: -200, opacity: 0 });


                            // 사회공헌활동
                            gsap.to('.social', { scrollTrigger: { trigger: '.social', start: 'top center' } });
                            gsap.from('.social .social__bg', { scrollTrigger: { trigger: '.social', start: '20% center', end: '-1000' + '%', scrub: 4 }, width: '400px', height: '400px', borderRadius: '50%', opacity: 0 });

                            gsap.from('.social .sec-wrap__tit', { scrollTrigger: { trigger: '.social', start: 'top center', end: '-1000' + '%', scrub: 5 }, y: 40, opacity: 0 });
                            gsap.from('.social .sec-wrap__desc', { scrollTrigger: { trigger: '.social', start: 'top center', end: '-1000' + '%', scrub: 6 }, y: 60, opacity: 0 });

                            gsap.from('.social__item1', { scrollTrigger: { trigger: '.social', start: '20% center', end: '-1000' + '%', scrub: 4 }, scale: 0 });
                            gsap.from('.social__item2', { scrollTrigger: { trigger: '.social', start: '20% center', end: '-1000' + '%', scrub: 6 }, scale: 0 });
                            gsap.from('.social__item3', { scrollTrigger: { trigger: '.social', start: '20% center', end: '-1000' + '%', scrub: 2 }, scale: 0 });
                            gsap.from('.social__item4', { scrollTrigger: { trigger: '.social', start: '20% center', end: '-1000' + '%', scrub: 8 }, scale: 0 });

                            // 이질감
                            gsap.to('.social__item1', { scrollTrigger: { trigger: '.social', start: '20% center', end: '100%', scrub: 4 }, y: -100 });
                            gsap.to('.social__item2', { scrollTrigger: { trigger: '.social', start: '20% center', end: '150%', scrub: 3 }, y: -70 });
                            gsap.to('.social__item3', { scrollTrigger: { trigger: '.social', start: '20% center', end: '170%', scrub: 5 }, y: -50 });
                            gsap.to('.social__item4', { scrollTrigger: { trigger: '.social', start: '20% center', end: '120%', scrub: 2 }, y: -80 });

                            // sns
                            gsap.from('.sns', { scrollTrigger: { trigger: '.sns', start: '10% center', end: '-1000' + '%', scrub: 5 }, background: '#fff' });
                            gsap.from('.sns__tit', { scrollTrigger: { trigger: '.sns', start: '10% center', end: '-1000' + '%', scrub: 3 }, y: 40, opacity: 0 });
                            gsap.from('.sns__desc', { scrollTrigger: { trigger: '.sns', start: '10% center', end: '-1000' + '%', scrub: 5 }, y: 70, opacity: 0 });
                            gsap.from('.icon__item1', { scrollTrigger: { trigger: '.sns', start: '10% center', end: '-1000' + '%', scrub: 2 }, x: 20, opacity: 0 });
                            gsap.from('.icon__item2', { scrollTrigger: { trigger: '.sns', start: '10% center', end: '-1000' + '%', scrub: 2 }, x: 30, opacity: 0 });
                            gsap.from('.icon__item3', { scrollTrigger: { trigger: '.sns', start: '10% center', end: '-1000' + '%', scrub: 2 }, x: 40, opacity: 0 });
                            gsap.from('.icon__item4', { scrollTrigger: { trigger: '.sns', start: '10% center', end: '-1000' + '%', scrub: 2 }, x: 50, opacity: 0 });
                            gsap.from('.sns__slide-btn', { scrollTrigger: { trigger: '.sns', start: '10% center', end: '-1000' + '%', scrub: 4 }, opacity: 0 });
                            gsap.from('.sns .swiper', { scrollTrigger: { trigger: '.sns', start: '10% center', end: '-1000' + '%', scrub: 5 }, x: 100, opacity: 0 });
                        } else {
                            // mo

                            // information
                            gsap.registerPlugin(ScrollTrigger);
                            gsap.from('.information__slide', { scrollTrigger: { trigger: '.information', start: '-40% center', end: '-500' + '%', scrub: 5 }, x: 100, opacity: 0 });
                            gsap.from('.information__box-mo', { scrollTrigger: { trigger: '.information__box-mo', start: '-70% center', end: '-500' + '%', scrub: 5,}, y: 50, opacity: 0 });
                            gsap.from('.information__box1', { scrollTrigger: { trigger: '.information__list-mo', start: 'top center', end: '-500' + '%', scrub: 5,  }, y: 50, opacity: 0 });
                            gsap.from('.information__box2', { scrollTrigger: { trigger: '.information__box1', start: 'top center', end: '-500' + '%', scrub: 5,}, y: 50, opacity: 0 });
                            gsap.from('.information__tit1', { scrollTrigger: { trigger: '.information', start: '20% center', end: '-500' + '%', scrub: 5 }, y: 40, opacity: 0 });
                            gsap.from('.information__desc1', { scrollTrigger: { trigger: '.information', start: '20% center', end: '-500' + '%', scrub: 6 }, y: 40, opacity: 0 });
                            gsap.from('.information__list.select .information__item1', { scrollTrigger: { trigger: '.information', start: '20% center', end: '-1000' + '%', scrub: 5 }, x: -100, opacity: 0 });
                            gsap.from('.information__list.select .information__item2', { scrollTrigger: { trigger: '.information', start: '20% center', end: '-2000' + '%', scrub: 5 }, x: -100, opacity: 0 });
                            gsap.from('.information__list.select .information__item3', { scrollTrigger: { trigger: '.information', start: '20% center', end: '-3000' + '%', scrub: 5 }, x: -100, opacity: 0 });
                            gsap.from('.information__tit2', { scrollTrigger: { trigger: '.information', start: '20% center', end: '-1000' + '%', scrub: 1 }, y: 40, opacity: 0 });
                            gsap.from('.information__desc2', { scrollTrigger: { trigger: '.information', start: '20% center', end: '-1000' + '%', scrub: 3 }, y: 40, opacity: 0, delay: 1 });
                            gsap.from('.information__list.link .information__item1', { scrollTrigger: { trigger: '.information', start: '20% center', end: '-2000' + '%', scrub: 1 }, y: '50%', x: '50%', opacity: 0 });
                            gsap.from('.information__list.link .information__item2', { scrollTrigger: { trigger: '.information', start: '20% center', end: '-2000' + '%', scrub: 1 }, y: '50%', x: '0%', opacity: 0 });
                            gsap.from('.information__list.link .information__item3', { scrollTrigger: { trigger: '.information', start: '20% center', end: '-2000' + '%', scrub: 1 }, y: '50%', x: '-50%', opacity: 0 });
                            gsap.from('.information__list.link .information__item4', { scrollTrigger: { trigger: '.information', start: '20% center', end: '-2000' + '%', scrub: 1 }, y: '-50%', x: '50%', opacity: 0 });
                            gsap.from('.information__list.link .information__item5', { scrollTrigger: { trigger: '.information', start: '20% center', end: '-2000' + '%', scrub: 1 }, y: '-50%', x: '0%', opacity: 0 });
                            gsap.from('.information__list.link .information__item6', { scrollTrigger: { trigger: '.information', start: '20% center', end: '-2000' + '%', scrub: 1 }, y: '-50%', x: '-50%', opacity: 0 });


                            // 검색
                            gsap.from('.sec-search .sec-wrap__tit', { scrollTrigger: { trigger: '.information__box2', start: 'top center', end: '-1000' + '%', scrub: 5 }, y: 40, opacity: 0 });
                            gsap.from('.sec-search .search__inner', { scrollTrigger: { trigger: '.information__box2', start: 'top center', end: '-1000' + '%', scrub: 5 }, y: 40, opacity: 0 });

                            // 이야기
                            gsap.from('.story .sec-wrap__tit', { scrollTrigger: { trigger: '.sec-search', start: 'top center', end: '-1000' + '%', scrub: 5 }, y: 40, opacity: 0 });
                            gsap.from('.story .sec-wrap__list', { scrollTrigger: { trigger: '.sec-search', start: '10% center', end: '-1000' + '%', scrub: 6 }, y: 60, opacity: 0 });
                            gsap.from('.story .story__list', { scrollTrigger: { trigger: '.sec-search', start: '10% center', end: '-1000' + '%', scrub: 6 }, x: 200, opacity: 0 });

                            // 칭찬
                            gsap.from('.compliment .sec-wrap__tit', { scrollTrigger: { trigger: '.story', start: 'top center', end: '-1000' + '%', scrub: 5 }, y: 40, opacity: 0 });
                            gsap.from(['.compliment .sec-wrap__desc', '.compliment .btn-wrap.type01'], { scrollTrigger: { trigger: '.story', start: '10% center', end: '-1000' + '%', scrub: 6 }, y: 60, opacity: 0 });
                            gsap.from('.compliment .compliment__list', { scrollTrigger: { trigger: '.story', start: '10% center', end: '-1000' + '%', scrub: 6 }, x: -200, opacity: 0 });


                            // 사회공헌활동
                            gsap.to('.social', { scrollTrigger: { trigger: '.compliment', start: 'top center' } });
                            gsap.from('.social .social__bg', { scrollTrigger: { trigger: '.compliment', start: '20% center', end: '-1000' + '%', scrub: 4 }, width: '400px', height: '400px', borderRadius: '50%', opacity: 0 });

                            gsap.from('.social .sec-wrap__tit', { scrollTrigger: { trigger: '.compliment', start: 'top center', end: '-1000' + '%', scrub: 5 }, y: 40, opacity: 0 });
                            gsap.from('.social .sec-wrap__desc', { scrollTrigger: { trigger: '.compliment', start: 'top center', end: '-1000' + '%', scrub: 6 }, y: 60, opacity: 0 });

                            gsap.from('.social__item1', { scrollTrigger: { trigger: '.compliment', start: '20% center', end: '-1000' + '%', scrub: 4 }, scale: 0 });
                            gsap.from('.social__item2', { scrollTrigger: { trigger: '.compliment', start: '20% center', end: '-1000' + '%', scrub: 6 }, scale: 0 });
                            gsap.from('.social__item3', { scrollTrigger: { trigger: '.compliment', start: '20% center', end: '-1000' + '%', scrub: 2 }, scale: 0 });
                            gsap.from('.social__item4', { scrollTrigger: { trigger: '.compliment', start: '20% center', end: '-1000' + '%', scrub: 8 }, scale: 0 });

                            // 이질감
                            // gsap.to('.social__item1', { scrollTrigger: { trigger: '.social', start: '20% center', end: '100%', scrub: 4 }, y: -50 });
                            // gsap.to('.social__item2', { scrollTrigger: { trigger: '.social', start: '20% center', end: '150%', scrub: 3 }, y: -40 });
                            // gsap.to('.social__item3', { scrollTrigger: { trigger: '.social', start: '20% center', end: '170%', scrub: 5 }, y: -30 });
                            // gsap.to('.social__item4', { scrollTrigger: { trigger: '.social', start: '20% center', end: '120%', scrub: 2 }, y: -40 });

                            // sns
                            gsap.from('.sns', { scrollTrigger: { trigger: '.sns', start: '10% center', end: '-1000' + '%', scrub: 5 }, background: '#fff' });
                            gsap.from('.sns__tit', { scrollTrigger: { trigger: '.sns', start: '10% center', end: '-1000' + '%', scrub: 3 }, y: 40, opacity: 0 });
                            gsap.from('.sns__desc', { scrollTrigger: { trigger: '.sns', start: '10% center', end: '-1000' + '%', scrub: 5 }, y: 70, opacity: 0 });
                            gsap.from('.icon__item1', { scrollTrigger: { trigger: '.sns', start: '10% center', end: '-1000' + '%', scrub: 2 }, x: 20, opacity: 0 });
                            gsap.from('.icon__item2', { scrollTrigger: { trigger: '.sns', start: '10% center', end: '-1000' + '%', scrub: 2 }, x: 30, opacity: 0 });
                            gsap.from('.icon__item3', { scrollTrigger: { trigger: '.sns', start: '10% center', end: '-1000' + '%', scrub: 2 }, x: 40, opacity: 0 });
                            gsap.from('.icon__item4', { scrollTrigger: { trigger: '.sns', start: '10% center', end: '-1000' + '%', scrub: 2 }, x: 50, opacity: 0 });
                            gsap.from('.sns__slide-btn', { scrollTrigger: { trigger: '.sns', start: '10% center', end: '-1000' + '%', scrub: 4 }, opacity: 0 });
                            gsap.from('.sns .swiper', { scrollTrigger: { trigger: '.sns', start: '10% center', end: '-1000' + '%', scrub: 5 }, x: 100, opacity: 0 });
                        }
                    })
                })
            },
            isExternal(url){
                return /^(http|https):/.test(url);
            },
             updateSwiper() {
                this.$nextTick(() => {
                    if (this.kvSwiper && this.kvSwiper.update) {
                        this.kvSwiper.update(); // Swiper 강제 업데이트
                    }
                });
            },
        },
        mounted() {

            console.log("store.state.memberStore.memCi: "+ this.$store.state.memberStore.memCi);
            console.log("store status: "+ this.$store.state.memberStore.status);
            if (this.$store.state.memberStore.status && this.$store.state.memberStore.memCi === '' ) { //
                const userResponse = confirm("창원파티마병원에 방문해주셔서 감사합니다. 확인을 누르면 계속 진행됩니다.");
                
                if (userResponse) {
                    // 확인 버튼을 눌렀을 때 동작
                    this.$router.push(`/member/subCert.do`);
                } else {
                    // 취소 버튼을 눌렀을 때 동작
                    console.log("취소 버튼을 눌렀습니다.");
                }
            }

            this.swiperNum()
            this.getMainBoardList()
            this.getDepartmentList()
            this.getHashTags()
            this.getThanksBanner()
            this.getSnsBanner()
            this.getMainBanner()
            this.updateSwiper();
            window.onpopstate = function(event) {
                if(event.state && event.state.current == '/'){
                    location.reload(true);
                }
            },
            setTimeout(() => {
                this.delayedNavigation = {
                    nextEl: ".sns .swiper-button-next",
                    prevEl: ".sns .swiper-button-prev",
                };
                this.$nextTick(() => {
                    if (this.$refs.snsSwiper && this.$refs.snsSwiper.swiper) {
                        this.$refs.snsSwiper.swiper.update(); // Swiper 업데이트
                    }
                });
            }, 500); 
        },
    }
</script>




