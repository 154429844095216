<template>
    <div>

        <div class="contents input-form type1">
            <ul class="input-form__list">
                <li class="input-form__item">
                    <div class="input-form__box">
                        <div class="tit">제목</div>
                        <div class="cont">{{articleInfo.title}}</div>
                    </div>
                </li>
                <li class="input-form__item item2">
                    <div class="input-form__box">
                        <div class="tit">이름</div>
                        <div class="cont">{{articleInfo.writer}}</div>
                    </div>
                    <div class="input-form__box">
                        <div class="tit">등록일자</div>
                        <div class="cont">
                            {{$commonUtils.dateToStr(new Date(articleInfo.createdDt), '.')}}
                        </div>
                    </div>
                </li>
                <li class="input-form__item">
                    <div class="input-form__box">
                        <div class="tit">내용</div>
                        <div class="cont" v-html="articleInfo.content"></div>
                    </div>
                </li>
                <li class="input-form__item" v-if="((boardId == 'praise' && articleInfo.item18 == 'O') || (boardId == 'healthcare' && articleInfo.item18 != 'R')) && $commonUtils.removeTags(articleInfo.reply)">
                    <div class="input-form__box">
                        <div class="tit">답변</div>
                        <div class="cont">
                            {{ $commonUtils.removeTags(articleInfo.reply) }}
                            <div class="date">{{$commonUtils.dateToStr(new Date(articleInfo.replyDt), '.')}}.</div>
                        </div>
                    </div>
                </li>
                <li class="input-form__item" v-if="articleInfo.attachBag && Object.keys(articleInfo.attachBag).length > 0">
                    <div class="input-form__box">
                        <div class="tit">첨부파일</div>
                        <div class="cont" style="cursor:pointer;" @click="$commonUtils.fileDownload(articleInfo.attachBag.attach[0].attachNo, articleInfo.attachBag.attach[0].displayName)">{{ articleInfo.attachBag.attach[0].displayName }}</div>
                    </div>
                </li>
            </ul>
        </div>

        <div class="contents btn-wrap center">
            <router-link :to="$route.path.replace('/view.do', '/list.do')" class="btn blue">목록</router-link>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                selectOn: false,
                boardNo: this.$route.query.boardNo | '',
                articleNo: this.$route.query.articleNo | '',
                boardId: this.$route.params.boardId,
                articleInfo: '',
                info: null,
            };
        },
        watch: {
            $route(to, from){
                for(let i in this.$route.query){
                    this[i] = this.$route.query[i]
                }
                this.getView()
            }
        },
        methods: {
            selectFn() {
                this.selectOn = !this.selectOn;
            },
            outsideClick(event) {
                if (!event.target.closest('.board__select')) {
                    this.selectOn = false;
                }
            },
            getView() {
                this.axios
                    .get(this.api.article.view + this.boardNo + '/' + this.articleNo, {
                        params: {
                            instNo: 1,
                            articleNo: this.articleNo,
                            boardNo: this.boardNo
                        }
                    })
                    .then((res) => {
                        this.articleInfo = res.data
                    });
            },
            goArticle(article){
                this.$router.push({path: this.$route.path, query: {boardNo: this.boardNo, articleNo: article.articleNo}})
            },
        },
        mounted() {
            document.addEventListener('click', this.outsideClick);
            this.getView()
        }
    };
</script>

<style>
    /* 스타일링을 원하는 경우 여기에 작성 */
</style>