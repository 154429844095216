<template>
    <div class="join-tab">
        <ul class="join-tab__list">
            <li class="join-tab__item">
                <div class="step"><span class="text">step </span><span class="num">01</span></div>
                <div class="tit">약관동의</div>
            </li>
            <li class="join-tab__item">
                <div class="step"><span class="text">step </span><span class="num">02</span></div>
                <div class="tit">본인인증</div>
            </li>
            <li class="join-tab__item active">
                <div class="step"><span class="text">step </span><span class="num">03</span></div>
                <div class="tit">회원정보 입력</div>
            </li>
            <li class="join-tab__item ">
                <div class="step"><span class="text">step </span><span class="num">04</span></div>
                <div class="tit">회원가입 완료</div>
            </li>
            <!-- 2024.12.04 수정
             <li class="join-tab__item" v-if="$store.state.memberStore.memType != 'B'">
                <div class="step"><span class="text">step </span><span class="num">02</span></div>
                <div class="tit">{{ $store.state.memberStore.memType == 'D' ? '보호자 및 ' : '' }}본인인증</div>
            </li>
            <li class="join-tab__item active">
                <div class="step"><span class="text">step </span><span class="num">{{ $store.state.memberStore.memType == 'B' ? '02' : '03' }}</span></div>
                <div class="tit">회원정보 입력</div>
            </li>
            <li class="join-tab__item ">
                <div class="step"><span class="text">step </span><span class="num">{{ $store.state.memberStore.memType == 'B' ? '03' : '04' }}</span></div>
                <div class="tit">회원가입 완료</div>
            </li> -->
        </ul>
    </div>

    <div class="join contents">
        <div class="input-form type1">
            <p class="input-form__top-text"><span class="c-red">* </span> 필수 입력항목</p>
            <ul class="input-form__list">
                <li class="input-form__item" v-if="$store.state.memberStore.memType != 'B'">
                    <div class="input-form__box">
                        <div class="tit"><span class="c-red">* </span>아이디</div>
                        <div class="cont">
                            <div class="cont-item">
                                <input type="text" class="cont__text-input" maxlength="12" ref="memId" v-on:keyup="confirmPw" v-model="memId">
                                <button class="btn" @click="confirmId">중복확인</button>
                            </div>
                            <p class="desc">영문,숫자조합 3~12자리, 첫 자는 반드시 영문 소문자 사용</p>
                        </div>
                    </div>
                </li>
                <li class="input-form__item" v-if="$store.state.memberStore.memType != 'B'">
                    <div class="input-form__box">
                        <div class="tit"><span class="c-red">* </span>비밀번호</div>
                        <div class="cont">
                            <div class="password" :class="memPwdValid == 'Y' ? 'active' : ''">
                                <input type="password" class="cont__text-input" maxlength="12"
                                v-on:keyup="confirmPw" v-model="memPwd" ref="memPwd">
                            </div>
                            <p class="desc">영문,숫자,특수문자 조합 8~12자리 사용 가능, 연속번호는 사용금지</p>
                            <p class="desc">특수문자 사용 가능 범위 : ~ ! @ # $ % ^ & * _ -</p>
                            <p class="desc">동일문자 연속 4개 사용금지</p>
                            <p class="desc">아이디와 동일한 문구 사용금지</p>
                        </div>

                    </div>
                </li>
                <li class="input-form__item" v-if="$store.state.memberStore.memType != 'B'">
                    <div class="input-form__box">
                        <div class="tit"><span class="c-red">* </span>비밀번호 확인</div>
                        <div class="cont">
                            <div class="password" :class="memPwdValid == 'Y' && memPwd == memPwdChk ? 'active' : ''">
                                <input type="password" class="cont__text-input" :class="memPwd != '' && memPwdChk != '' && memPwd != memPwdChk ? 'b-red' : ''"  maxlength="12"
                                title="비밀번호 확인" v-model="memPwdChk" ref="memPwdChk">
                            </div>
                            <p class="desc c-red" v-show="memPwd != '' && memPwdChk != '' && memPwd != memPwdChk">비밀번호가 일치하지 않습니다.</p>
                        </div>
                    </div>
                </li>
                <li class="input-form__item">
                    <div class="input-form__box">
                        <div class="tit"><span class="c-red">* </span>이름</div>
                        <div class="cont">
                            <input type="text" class="cont__text-input" v-model="memName" :readonly="$store.state.memberStore.memName">
                        </div>
                    </div>
                </li>
                <li class="input-form__item">
                    <div class="input-form__box phon">
                        <div class="tit"><span class="c-red">* </span>휴대전화</div>
                        <!-- new -->
                        <div class="cont row" v-if="memHpNo"> <!-- this.$store.state.memberStore.memType == 'B' && -->
                            <div>{{ memHpNo }}</div>
                        </div>
                        <!-- <div class="cont row" v-else>
                            <input type="text" class="cont__text-input" maxlength="3" v-model="memHpNo1" @input="this.$commonUtils.numberOnly"> <span class="line">-</span>
                            <input type="text" class="cont__text-input" maxlength="4" v-model="memHpNo2" @input="this.$commonUtils.numberOnly"> <span class="line">-</span>
                            <input type="text" class="cont__text-input" maxlength="4" v-model="memHpNo3" @input="this.$commonUtils.numberOnly">
                        </div> -->
                        <!-- old -->
                        <!-- <div class="cont row">
                            <input type="text" class="cont__text-input" maxlength="3" v-model="memHpNo1" @input="this.$commonUtils.numberOnly"> <span class="line">-</span>
                            <input type="text" class="cont__text-input" maxlength="4" v-model="memHpNo2" @input="this.$commonUtils.numberOnly"> <span class="line">-</span>
                            <input type="text" class="cont__text-input" maxlength="4" v-model="memHpNo3" @input="this.$commonUtils.numberOnly">
                        </div> -->
                    </div>
                </li>
                <li class="input-form__item">
                    <div class="input-form__box email">
                        <div class="tit"><span class="c-red">* </span>이메일</div>
                        <div class="cont row">
                            <input type="text" class="cont__text-input" v-model="memEmail1"> <span class="line">@</span>
                            <input type="text" class="cont__text-input" v-model="memEmail2">
                            <select class="cont__select" ref="memEmailSelect" v-on:change="memEmail2 = this.$refs.memEmailSelect.value">
                                <option value="직접입력">직접입력</option>
                                <option value="naver.com">naver.com</option>
                                <option value="daum.net">daum.net</option>
                                <option value="gmail.com">gmail.com</option>
                                <option value="yahoo.com">yahoo.com</option>
                                <option value="nate.com">nate.com</option>
                                <option value="korea.com">korea.com</option>
                                <option value="chol.com">chol.com</option>
                            </select>
                        </div>
                    </div>
                </li>
                <li class="input-form__item">
                    <div class="input-form__box phon">
                        <div class="tit">유선 전화번호</div>
                        <div class="cont row">
                            <input type="text" class="cont__text-input" maxlength="3" v-model="memTel1"> <span class="line">-</span>
                            <input type="text" class="cont__text-input" maxlength="4" v-model="memTel2"> <span class="line">-</span>
                            <input type="text" class="cont__text-input" maxlength="4" v-model="memTel3">
                        </div>
                    </div>
                </li>
                <li class="input-form__item">
                    <div class="input-form__box address">
                        <div class="tit">주소</div>
                        <div class="cont">
                            <div class="cont-item">
                                <input type="text" class="cont__text-input" readonly v-model="memZipCode" @click="zipcodeApi">
                                <button class="btn" @click="zipcodeApi">우편번호 검색</button>
                            </div>
                            <div class="cont-item">
                                <input type="text" class="cont__text-input" readonly v-model="memAddress1">
                            </div>
                            <div class="cont-item">
                                <input type="text" class="cont__text-input" v-model="memAddress2">
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <div class="contents btn-wrap center">
            <a href="javascript:;" class="btn" @click="cancleSubs">가입취소</a>
            <a href="javascript:;" class="btn blue" @click="signUp">다음단계</a>
        </div>
        
        <div id="zipcodeWrap" style="display:none;">
            <div ref="embed" class="zipcodeWrap_style"
                style="display: block; position: fixed; overflow: hidden; z-index: 1;border: 3px solid;">
                <div style="height:30px;background:white;">
                    <button id="btnCloseLayer" @click="closeDaumPostcode()"  style="cursor:pointer;position:absolute;right:-3px;top:-3px;z-index:1; height: 40px; width: 40px;padding: 0;border: none; font-size:24px;">
                        x
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import $ from 'jquery'

export default {
    components: {
    },
    data() {
        return {            
            processing: false,

            // 회원가입 컬럼값
            memId: this.$store.state.memberStore.memId || '',
            memIdValid: this.$store.state.memberStore.memId || '',
            memPwd: this.$store.state.memberStore.memId || '',
            memPwdChk: this.$store.state.memberStore.memId || '',
            memPwdValid: this.$store.state.memberStore.memId != '' ? 'Y' : '',
            memName: this.$store.state.memberStore.memName || '',
            memHpNo1: '',
            memHpNo2: '',
            memHpNo3: '',
            memHpNo: this.$store.state.memberStore.memHpNo,
            sns: 'N',
            memEmail1: '',
            memEmail2: '',
            memTel1: '',
            memTel2: '',
            memTel3: '',
            news: '',
            memZipCode: '',
            memAddress1: '',
            memAddress2: '',
        }
    },
    mounted() {
        
    },
    methods: {
        // ID 중복확인
        confirmId() {
            const smallLetter = /^[a-z]$/
            const eng = /^[a-zA-z]*$/
            const num  = /^[0-9]*$/
            const regexp = /^[a-zA-z](?=.*\d)[a-zA-z\d]{2,11}$/
            if (this.memId == '') {
                alert('아이디을(를) 입력해주세요.')
                this.$refs.memId.focus()
                return
            }
            if(this.memId.length < 3) {
                alert('아이디는 최소 3자리부터 시작합니다.')
                this.memId = ''
                this.$refs.memId.focus()
                return
            }
            if(!smallLetter.test(this.memId.slice(0, 1))) {
                alert('첫 자는 반드시 영문 소문자를 사용해야합니다.')
                this.memId = '';
                this.$refs.memId.focus()
                return
            }
            if(eng.test(this.memId) || num.test(this.memId)) {
                alert('영문, 숫자조합 3~12자리를 사용해야합니다.')
                this.memId = ''
                this.$refs.memId.focus()
                return
            }
            if(regexp.test(this.memId)) {
                this.axios({
                    url: this.api.member.dupId,
                    method: 'get',
                    params: {
                        memId: this.memId
                    }
                })
                    .then(res => {
                        if (res.data.result.check === 'Y') {
                            if (confirm(`입력하신 ID는 사용할 수 있습니다.\n아이디를 사용하시겠습니까?`)) {
                                this.memIdValid = this.memId;
                            }
                        } else {
                            alert(`입력하신 ID는 중복된 ID입니다.\n다시 입력해주시길 바랍니다.`);
                            this.memId = '';
                        }
                    })
                    .catch(err => {
                        console.log(err)
                    });

            }else{
                alert('특수문자가 존재하거나 잘못된 조합입니다.\n다시 입력해주시길 바랍니다.')
                this.memId = ''
                this.$refs.memId.focus()
            }
        },
        // pw 조건식 일치 여부
        confirmPw() {
            const reg = /^(?=.*[a-zA-Z])(?=.*[~!@#$%^&*_-])(?=.*[0-9]).{8,12}$/; // 영문, 숫자, 특수문자 인식
            const regNum = /(012)|(123)|(234)|(345)|(456)|(567)|(678)|(789)|(987)|(876)|(765)|(654)|(543)|(432)|(321)/; // 연속 숫자
            const regAlp = /(abc)|(bcd)|(cde)|(def)|(efg)|(fgh)|(ghi)|(hij)|(ijk)|(jkl)|(klm)|(lmn)|(mno)|(nop)|(opq)|(pqr)|(qrs)|(rst)|(stu)|(tuv)|(uvw)|(vwx)|(wxy)|(xyz)|(zyx)|(yxw)|(xwv)|(wvu)|(vut)|(uts)|(tsr)|(srq)|(rqp)|(qpo)|(pon)|(onm)|(nml)|(mlk)|(lkj)|(kji)|(jih)|(ihg)|(hgf)|(gfe)|(fed)|(edc)|(dcb)|(cba)/; // 연속 문자
            const sameCheck = /([0-9a-zA-Z])\1{3,}/; // 동일문자 연속 4개 이상 확인

            if (this.memPwd == '') {
                this.memPwdValid = ''
            }else if (reg.test(this.memPwd) && !regNum.test(this.memPwd) && !regAlp.test(this.memPwd) && !sameCheck.test(this.memPwd) && this.memPwd.indexOf(this.memId) < 0) {
                this.memPwdValid = 'Y'
            } else {
                this.memPwdValid = 'N'
            }
        },
        // 우편번호 찾기 api
        zipcodeApi() {
            const zipcodeWrap = document.getElementById('zipcodeWrap');
            zipcodeWrap.style.display = 'block';
            const zipCode = document.getElementById('Signin_adress_num01');
            const address = document.getElementById('Signin_adress_detail1');
            new window.daum.Postcode({
                oncomplete: (data) => {
                    // 팝업에서 검색결과 항목을 클릭했을때 실행할 코드를 작성하는 부분.

                    // 도로명 주소의 노출 규칙에 따라 주소를 조합한다.
                    // 내려오는 변수가 값이 없는 경우엔 공백('')값을 가지므로, 이를 참고하여 분기 한다.
                    let fullRoadAddr = data.roadAddress; // 도로명 주소 변수
                    let extraRoadAddr = ''; // 도로명 조합형 주소 변수

                    // 법정동명이 있을 경우 추가한다. (법정리는 제외)
                    // 법정동의 경우 마지막 문자가 "동/로/가"로 끝난다.
                    if (data.bname !== '' && /[동|로|가]$/g.test(data.bname)) {
                        extraRoadAddr += data.bname;
                    }
                    // 건물명이 있고, 공동주택일 경우 추가한다.
                    if (data.buildingName !== '' && data.apartment === 'Y') {
                        extraRoadAddr += (extraRoadAddr !== '' ? ', ' + data.buildingName : data.buildingName);
                    }
                    // 도로명, 지번 조합형 주소가 있을 경우, 괄호까지 추가한 최종 문자열을 만든다.
                    if (extraRoadAddr !== '') {
                        extraRoadAddr = ' (' + extraRoadAddr + ')';
                    }
                    // 도로명, 지번 주소의 유무에 따라 해당 조합형 주소를 추가한다.
                    if (fullRoadAddr !== '') {
                        fullRoadAddr += extraRoadAddr;
                    }

                    // 우편번호와 주소 정보를 해당 필드에 넣는다.
                    this.memZipCode = data.zonecode;
                    this.memAddress1 = fullRoadAddr;

                    // 주소 정보 클릭하면 팝업 close
                    this.closeDaumPostcode();
                }
            }).embed(this.$refs.embed);
            //.open(); 사용시 팝업창 생성
            const width = innerWidth
            if(width <= 720){
                const el = document.querySelector('[id^="__daum__layer_"]')
                if(el){
                    el.style.width = "100%";
                    el.style.maxWidth = "360px";
                    el.style.height = "500px";
                }
            }
        },
        closeDaumPostcode() {
            const zipcodeWrap = document.getElementById('zipcodeWrap');
            zipcodeWrap.style.display = 'none';
            $(".postal_button").attr("tabindex", "0").focus();
        },
        // 가입취소
        cancleSubs() {
            if (confirm('회원가입을 취소하시겠습니까?')) {
                this.$router.push(`/member/join.do`)
            }
        },
        // 회원가입
        signUp() {
            if(this.$store.state.memberStore.memCi == '' && this.$store.state.memberStore.memDi == '' ){ //&& this.$store.state.memberStore.memType != 'B'
                alert('올바르지 않은 접근입니다.')
                this.$store.commit('init')
                this.$router.push('/')
                return
            }
            if (this.memId == '') {
                alert('아이디를(을) 입력해주세요.');
                this.$refs.memId.focus()
                return
            }
            if (this.memIdValid == '') {
                alert('아이디 중복확인을 해주세요.');
                return
            }
            if (this.memIdValid != this.memId) {
                alert('아이디 중복확인 이후 아이디를 변경하였습니다.\n다시 아이디 중복확인을 해주시길 바랍니다.');
                return
            }
            if (this.memPwd == '') {
                alert('비밀번호를(을) 입력해주세요.');
                this.$refs.memPwd.focus();
                return
            }
            if (this.memPwdValid != 'Y') {
                alert('비밀번호는(을) 영문, 숫자, 특수문자 조합 8~12자리 사용 가능하고 연속번호는 사용금지입니다.\n다시 입력해주시기 바랍니다.');
                this.memPwd = '';
                this.$refs.memPwd.focus();
                return
            }
            if (this.memPwd != this.memPwdChk) {
                alert('비밀번호와 비밀번호 확인이 일치하지않습니다.');
                this.$refs.memPwdChk.focus();
                return
            }
            // new
            // if(!(this.$store.state.memberStore.memType == 'B' && this.memHpNo)){
            //     if (this.memHpNo1 == '' || this.memHpNo2 == '' || this.memHpNo3 == '') {
            //         alert('휴대전화 번호를 입력해주세요.');
            //         return
            //     }
            // }
            // if (this.memEmail1 == '' || this.memEmail2 == '') {
            //     alert('이메일을(를) 입력해주세요.');
            //     return
            // }
            // const regEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            // if (!regEmail.test(this.memEmail1 + '@' + this.memEmail2)) {
            //     alert('올바른 형식의 이메일을(를) 입력해주세요.');
            //     return
            // }
            // old
            if(this.$store.state.memberStore.memType != 'B'){
                // if (this.memHpNo1 == '' || this.memHpNo2 == '' || this.memHpNo3 == '') {
                //     alert('휴대전화 번호를 입력해주세요.');
                //     return
                // }
                if (this.memEmail1 == '' || this.memEmail2 == '') {
                    alert('이메일을(를) 입력해주세요.');
                    return
                }
                const spaceChk = /\s/g;
                if(this.memEmail1.match(spaceChk) || this.memEmail2.match(spaceChk)){
                    alert('이메일에 공백이 존재합니다.\n공백을 제거해주세요.');
                    return
                }
                const regEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                if (!regEmail.test(this.memEmail1 + '@' + this.memEmail2)) {
                    alert('올바른 형식의 이메일을(를) 입력해주세요.');
                    return
                }
            }
            const joinType = this.$store.state.memberStore.memType == 'D' ? 'child' : 
                            this.$store.state.memberStore.memType == 'B' ? 'sns' : 'normal'
            const params = new URLSearchParams();

            let memEmail = this.memEmail1 + '@' + this.memEmail2
            memEmail = memEmail.replaceAll(" ", "")
            const memHpNo = this.$store.state.memberStore.memHpNo || this.memHpNo1 + '-' + this.memHpNo2 + '-' + this.memHpNo3

            params.append('memId', this.memId);
            params.append('memPwd', btoa(this.memPwd));
            params.append('memName', this.memName);
            params.append('memEmail', memEmail);
            params.append('memHpNo', memHpNo);
            params.append('memBirth', this.$store.state.memberStore.memBirth);
            params.append('memGender', this.$store.state.memberStore.memGender);
            params.append('memTel', this.setTel());
            params.append('memCi', this.$store.state.memberStore.memCi);
            params.append('memDi', this.$store.state.memberStore.memDi);
            params.append('memJoinRoute', 'P');
            params.append('memZipcode', this.memZipCode);
            params.append('memAddress1', this.memAddress1);
            params.append('memAddress2', this.memAddress2);
            params.append('memType', this.$store.state.memberStore.memType);
            params.append('memSmsAgreeYn', this.sns);
            params.append('memThirdpartyProvideAgreeYn', 'N');
            params.append('joinType', joinType);
            params.append('etcNewsYn', this.news == true ? 'Y' : 'N');
            if (joinType == 'child') {
                params.append('protectorInfo.instMemNo', this.$store.state.memberStore.parent.instMemNo);
                params.append('protectorInfo.mphName', this.$store.state.memberStore.parent.mphName);
                params.append('protectorInfo.mphHpNo', this.$store.state.memberStore.parent.mphHpNo);
                params.append('protectorInfo.mphEmail', this.$store.state.memberStore.parent.mphEmail);
                params.append('protectorInfo.mphBirth', this.$store.state.memberStore.parent.mphBirth);
            }
            params.append('marketingCheck', this.$store.state.memberStore.marketingCheck);
            var snsType = this.$store.state.memberStore.memSnsType;
            if(snsType){
                params.append('memSnsType', snsType);
            }

            if(this.processing){
                alert('처리 중입니다.')
            }else{
                this.processing = true
                this.axios
                .post(this.api.member.regist, params)
                .then(res => {
                    if (res.data.result.status) {
                        const finishId = this.$store.state.memberStore.memSnsType == 'NA' ?
                                        'SNS-Naver 회원' :
                                        'SNS-Kakao 회원'
                        const mem = {
                            memName: this.memName,
                            memId: this.$store.state.memberStore.memType == 'B' ? finishId : this.memId,
                            memEmail: this.memEmail1 + '@' + this.memEmail2
                        }
                        this.processing = false
                        this.$store.commit('setFinish', mem)
                        this.$router.push(`/member/complete.do`);
                    } else {
                        alert('회원가입에 실패하였습니다. 다시 시도해주세요.');
                        location.reload();
                    }
                })
                .catch(error => {
                    alert('회원가입에 실패하였습니다. 다시 시도해주세요.');
                    location.reload();
                });
            }
        },
        setTel(){
            let tel = [this.memTel1, this.memTel2, this.memTel3].filter(num => num != '');
            let setNumber = tel.join('-');
            return setNumber;
        },
    },
}
</script>

<style scoped>
.zipcodeWrap_style{width: 100% !important; max-width: 500px !important; height: 500px !important; top: 50% !important; left: 50% !important; transform: translate(-50%, -50%) !important;}

@media(max-width: 720px){
.zipcodeWrap_style{width: 100% !important; max-width: 360px !important; height: 500px !important;}
}
</style>
