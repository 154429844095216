<template>
    <div class="join-tab">
        <ul class="join-tab__list">
            <li class="join-tab__item">
                <div class="step"><span class="text">step </span><span class="num">01</span></div>
                <div class="tit">약관동의</div>
            </li>
            <li class="join-tab__item active">
                <div class="step"><span class="text">step </span><span class="num">02</span></div>
                <div class="tit">본인인증</div>
            </li>
            <li class="join-tab__item">
                <div class="step"><span class="text">step </span><span class="num">03</span></div>
                <div class="tit">회원정보 입력</div>
            </li>
            <li class="join-tab__item ">
                <div class="step"><span class="text">step </span><span class="num">04</span></div>
                <div class="tit">회원가입 완료</div>
            </li>
            <!-- <li class="join-tab__item active" v-if="$store.state.memberStore.memType != 'B'">
                <div class="step"><span class="text">step </span><span class="num">02</span></div>
                <div class="tit">{{ $store.state.memberStore.memType == 'D' ? '보호자 및 ' : '' }}본인인증</div>
            </li>
            <li class="join-tab__item">
                <div class="step"><span class="text">step </span><span class="num">{{ $store.state.memberStore.memType == 'B' ? '02' : '03' }}</span></div>
                <div class="tit">회원정보 입력</div>
            </li>
            <li class="join-tab__item ">
                <div class="step"><span class="text">step </span><span class="num">{{ $store.state.memberStore.memType == 'B' ? '03' : '04' }}</span></div>
                <div class="tit">회원가입 완료</div>
            </li> -->
        </ul>
    </div>

    <div class="contents info-text depth__wrap depth01" v-if="$store.state.memberStore.memType == 'D' && childChk">
        <div class="depth__bullet"><span class="bullet01"></span></div>
        <div class="depth__text">
            <h4 class="depth01__title title">법정대리인(보호자) 확인 및 정보수집 동의</h4>
            <div class="depth__wrap depth02">
                <div class="depth__text">
                    <div class="depth__wrap depth03">
                        <div class="depth__text">
                            <div class="depth__wrap">
                                <div class="depth__bullet"><span class="bullet03"></span></div>
                                <div class="depth__text">
                                    <p>법정대리인(보호자) 아이디와 비밀번호, 이름을 기재하여 회원 확인이 필요합니다.</p>
                                </div>
                            </div>
                            <div class="depth__wrap">
                                <div class="depth__bullet"><span class="bullet03"></span></div>
                                <div class="depth__text">
                                    <p>법정대리인이 회원으로 가입되어 있지 않을 경우 법정대리인의 회원가입이 필요합니다.</p>
                                </div>
                            </div>
                            <div class="depth__wrap">
                                <div class="depth__bullet"><span class="bullet03"></span></div>
                                <div class="depth__text">
                                    <p>만 14세 미만 아동이 홈페이지 회원을 가입하기 위해서는 법정대리인의 동의가 필요합니다. 이에 따라 창원파티마병원은 법정대리인의 다음 정보를 수집합니다.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="contents text-box type2" v-if="$store.state.memberStore.memType == 'D' && childChk">
        <div class="text-box__wrap type2">
            <div class="depth__wrap depth04">
                <div class="depth__bullet">
                    <p>①</p>
                </div>
                <div class="depth__text">
                    <p>항목 : 기존에 가입되어 있는 법정대리인의 개인정보 항목 중 아이디, 비밀번호, 이름, 법정대리인 동의 여부</p>
                </div>
            </div>
            <div class="depth__wrap depth04">
                <div class="depth__bullet">
                    <p>②</p>
                </div>
                <div class="depth__text">
                    <p>목적 : 만14세 미만 아동의 홈페이지 회원가입</p>
                </div>
            </div>
            <div class="depth__wrap depth04">
                <div class="depth__bullet">
                    <p>③</p>
                </div>
                <div class="depth__text">
                    <p>기간 : 법정대리인이 회원가입을 탈퇴하거나 제명된 때</p>
                </div>
            </div>
            <div class="depth__wrap depth04">
                <div class="depth__bullet">
                    <p class="c-red">*</p>
                </div>
                <div class="depth__text">
                    <p class="c-red">법정대리인은 위 내용에 대해 동의를 거부할 수 있습니다. 다만 법정대리인의 동의 거부 시, 만 14세 미만 아동의 홈페이지 회원가입은 제한됨을 알려드립니다.</p>
                </div>
            </div>
        </div>
        <div class="agree-input">
            <label for="agree-y" class="agree-input__item">
                <input type="radio" id="agree-y" class="agree-input__radio" name="agree" ref="parentAgree">
                <span class="icon"></span> 동의합니다
            </label>
        </div>
    </div>

    <div class="contents text-btn" v-if="$store.state.memberStore.memType == 'D' && childChk">
        <div class="text">
            <p><b>법정대리인(보호자)</b>가 병원 홈페이지의 <b>회원 아이디/패스워드</b>를 알고 계셔야 합니다.</p>
        </div>
        <div class="btn">
            <div class="btn-wrap">
                <a href="/member/idInquiry.do" class="btn fff_bg" target="_blank">아이디 찾기</a>
                <a href="/member/pwInquiry.do" class="btn fff_bg" target="_blank">비밀번호 찾기</a>
            </div>
        </div>
    </div>

    <div class="join contents" v-if="$store.state.memberStore.memType == 'D' && childChk">
        <div class="input-form type1">
            <p class="input-form__top-text"><span class="c-red">* </span> 필수 입력항목</p>
            <ul class="input-form__list">
                <li class="input-form__item">
                    <div class="input-form__box">
                        <div class="tit"><span class="c-red">* </span>아이디</div>
                        <div class="cont">
                            <div class="cont-item">
                                <input type="text" class="cont__text-input" v-model="parentMemId">
                            </div>
                        </div>
                    </div>
                </li>
                <li class="input-form__item">
                    <div class="input-form__box">
                        <div class="tit"><span class="c-red">* </span>비밀번호</div>
                        <div class="cont">
                            <input type="password" class="cont__text-input" v-model="parentMemPwd" maxlength="12">
                        </div>
                    </div>
                </li>
                <li class="input-form__item">
                    <div class="input-form__box">
                        <div class="tit"><span class="c-red">* </span>성명</div>
                        <div class="cont">
                            <input type="text" class="cont__text-input" v-model="parentMemName">
                        </div>
                    </div>
                </li>

            </ul>
        </div>
        <div class="contents btn-wrap center">
            <a href="javascript:;" class="btn blue" @click="chkParentInfo()">확인</a>
        </div>
    </div>

    <div class="certification" v-if="!childChk">
        <ul class="contents login-box__list">
            <li class="login-box__item">
                <div class="icon"><img src="@/assets/resource/icon/login-icon01.png" alt="이미지"></div>
                <h3 class="tit" v-if="$store.state.memberStore.memType == 'D'">
                    14세 미만 어린이<br>본인 명의의 휴대폰 인증</h3>
                <h3 class="tit" v-else>본인 인증</h3>
                <p class="desc">본인명의 휴대폰</p>
                <div class="btn-wrap center">
                    <a href="javascript:;" class="btn blue" title="새 창 열림" @click="this.$refs.NiceIdVue.fnPopup('regist')">
                        <span>인증하기</span>
                    </a>
                </div>
            </li>
            <li class="login-box__item">
                <div class="icon"><img src="@/assets/resource/icon/login-icon02.png" alt="이미지"></div>
                <h3 class="tit" v-if="$store.state.memberStore.memType == 'D'">
                    14세 미만 어린이 본인<br>아이핀 인증</h3>
                <h3 class="tit" v-else>아이핀 인증</h3>
                <p class="desc">아이핀 ID/PW</p>
                <div class="btn-wrap center">
                    <a href="javascript:;" class="btn blue" title="새 창 열림" @click="this.$refs.NiceIdVue.popIpinAuth('regist')">
                        <span>인증하기</span>
                    </a>
                </div>
            </li>
        </ul>
    </div>

    <!-- <div class="contents info-text depth__wrap depth01">
        <div class="depth__bullet"><span class="bullet01"></span></div>
        <div class="depth__text">
            <h4 class="depth01__title title">유의사항</h4>
            <div class="depth__wrap depth02">
                <div class="depth__text">
                    <div class="depth__wrap depth03">
                        <div class="depth__text">
                            <div class="depth__wrap" v-if="$store.state.memberStore.memType == 'D'">
                                <div class="depth__bullet"><span class="bullet03"></span></div>
                                <div class="depth__text">
                                    <p>어린이 회원가입은 본인인증(휴대폰 또는 아이핀)과 법적대리인(보호자)인증 후 가입이 가능합니다.</p>
                                </div>
                            </div>
                            <div class="depth__wrap">
                                <div class="depth__bullet"><span class="bullet03"></span></div>
                                <div class="depth__text">
                                    <p>본인인증 방법 선택 후 팝업창이 나타나지 않으면 브라우저의 팝업차단을 해제해 주시기 바랍니다.</p>
                                </div>
                            </div>
                            <div class="depth__wrap">
                                <div class="depth__bullet"><span class="bullet03"></span></div>
                                <div class="depth__text">
                                    <p>아이핀(I-PIN) 인증 또는 휴대폰 인증시 장애가 있으신 경우는 나이스평가정보 실명확인 서비스 기관에 문의하시기 바랍니다. <br>
                                        나이스평가정보 본인 인증 콜센터 : 1600-1522</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <NiceIdVue ref="NiceIdVue"></NiceIdVue>
</template>

<script>
import NiceIdVue from '../util/NiceId.vue';
export default {
    components: {
        NiceIdVue
    },
    data() {
        return {
            childChk: this.$store.state.memberStore.memType == 'D' ? true : false,

            parentMemId: '',
            parentMemPwd: '',
            parentMemName: '',
        }
    },
    mounted() {
        // 본인인증 후 회원정보 입력 페이지로 이동
        if(this.$store.state.memberStore.memCi != ''){
            this.$router.push(`/member/register.do`)
        }
    },
    methods: {
        // 부모 인증
        chkParentInfo() {
            if(!this.$refs.parentAgree.checked) {
                alert('법정대리인(보호자) 확인 및 정보수집에 동의해주세요.');
                return
            }
            if(!this.parentMemId) {
                alert('법정대리인(보호자) 아이디를 입력해주세요.');
                return
            }
            if(!this.parentMemPwd) {
                alert('법정대리인(보호자) 비밀번호를 입력해주세요.');
                return
            }
            if(!this.parentMemName) {
                alert('법정대리인(보호자) 이름을 입력해주세요.');
                return
            }
            if (this.$refs.parentAgree.checked) {
                this.axios
                    .get(this.api.member.parentCheck, {
                        params: {
                            memName: this.parentMemName,
                            memId: this.parentMemId,
                            memPwdEnc: btoa(this.parentMemPwd)
                        }
                    })
                    .then(res => {
                        const isYn = res.data.isYn;
                        if (isYn == 'Y') {
                            const parent = {
                                instMemNo : res.data.instMemNo,
                                mphName : res.data.mphName,
                                mphHpNo : res.data.mphHpNo,
                                mphEmail : res.data.mphEmail,
                                mphBirth : res.data.mphBirth
                            }
                            this.$store.commit('setParent', parent);
                            this.childChk = false
                            alert('보호자 인증이 완료되었습니다.');
                        } else {
                            alert('인증에 실패하였습니다.\n다시 한번 시도해주시길 바랍니다.');
                        }
                    })
            }
        },
    },
}
</script>