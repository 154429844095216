import { createRouter, createWebHistory } from 'vue-router';
import { isLoginCheck, isNotLoginCheck } from './navigationGuard';
import MainView from '../views/main/MainView.vue';
import BoardList from '../views/board/BoardList.vue';
import Sample from '../views/sample/SampleView.vue';

import Search from '../views/search/SearchView.vue';

import Customer1 from '@/components/퍼블팀/샘플/Customer1.vue';
import Customer2 from '@/components/퍼블팀/샘플/Customer2.vue';
import Customer3 from '@/components/퍼블팀/샘플/Customer3.vue';
import department1 from '@/components/퍼블팀/진료과_의료진/진료과/진료과 안내 리스트.vue';
import department1_1 from '@/components/퍼블팀/진료과_의료진/진료과/진료과 리스트 상세.vue';
import department1_2 from '@/components/퍼블팀/진료과_의료진/진료과/의료진프로필.vue';
import department2 from '@/components/퍼블팀/진료과_의료진/의료진/의료진검색 리스트.vue';
import department2_1 from '@/components/퍼블팀/진료과_의료진/진료과/진료과 리스트 상세.vue';
import department3 from '@/components/퍼블팀/진료과_의료진/센터클리닉 리스트.vue';
import department4 from '@/components/퍼블팀/진료과_의료진/진료일정표.vue';

// 고객보여주기용 (질병정보 컨펌용)
import disease from '@/components/퍼블팀/건강정보/건강_질병정보_type02.vue';
import Work from '../views/sample/WorkView.vue';

import routeComponentMapping from '@/router/autoRouteMapping'; // autoRouteMapping에서 생성된 매핑 객체를 가져옵니다.

const staticRoutes = [
    {
        path: '/',
        name: 'main',
        component: MainView
    },
    {
        path: '/BoardList.do',
        name: 'board',
        component: BoardList
    },
    // 부서홈페이지
    {
        path: '/deptHp/:deptCd/:hpUrl/main.do',
        name: 'deptHpMain',
        component: () => import('@/views/dept/DeptMainView.vue'),
        props: true
    },
    {
        path: '/deptHp/:deptCd/:hpUrl/:path1.do',
        name: 'deptHpContain',
        component: () => import('@/views/dept/DeptContainView.vue'),
        alias: ['/deptHp/:deptCd/:hpUrl/:path1/:path2.do', '/deptHp/:deptCd/:hpUrl/:path1/:path2/:path3.do']
    },
    {
        path: '/deptHp/:deptCd/:hpUrl/:boardId/view.do',
        name: 'deptBoardView',
        component: () => import('@/views/dept/DeptBoardDetailView.vue')
    },
    //게시판 상세 View
    {
        path: '/:boardId/view.do',
        name: 'boardView',
        component: () => import('@/views/board/BoardDetailView.vue')
    },
    {
        path: '/member/subCert.do',
        name: 'subCert',
        component: () => import('@/views/member/MemberView.vue')
    },
    //건강정보포탈 상세 View
    // {
    //     path: '/healthinfo/cancerView.do',
    //     name: 'healthInfo',
    //     component: () => import('@/views/board/HealthInfoDetailView.vue'),
    //     alias: ['/healthinfo/mentalView.do', '/healthinfo/diseaseView.do', '/healthinfo/treatView.do', '/healthinfo/inspecView.do']
    // },
    // 게시판, 컨텐츠, 링크 통합 view
    {
        path: '/:path1.do',
        name: 'contain',
        component: () => import('@/views/contain/ContainView.vue'),
        alias: ['/:path1/:path2.do', '/:path1/:path2/:path3.do', '/:path1/:path2/:path3/:path4.do']
    },
    // 회원가입 view
    // {
    //     path: '/member/:path1.do',
    //     name: 'member',
    //     component: () => import('@/views/member/MemberView.vue')
    // },
    {
        path: '/search.do',
        name: 'search',
        component: Search
    },
    {
        path: '/cvr_confirmation',
        name: 'CVR',
        component: () => import('@/components/util/Cvr.vue')
    },
    {
        path: '/sample.do',
        name: 'sample',
        component: Sample
    },
    {
        path: '/work.do',
        name: 'work',
        component: Work
    },
    { path: '/customer1.do', component: Customer1 },
    { path: '/customer2.do', component: Customer2 },
    { path: '/customer3.do', component: Customer3 },
    { path: '/department1.do', component: department1 },
    { path: '/department1_1.do', component: department1_1 },
    { path: '/department1_2.do', component: department1_2 },
    { path: '/department2.do', component: department2 },
    { path: '/department2_1.do', component: department2_1 },
    { path: '/department3.do', component: department3 },
    { path: '/department4.do', component: department4 },
    { path: '/disease.do', component: disease },
    {
        path: '/praise/register.do',
        name: 'praiseRegister',
        component: () => import('@/views/board/PraiseRegisterView.vue'),
        beforeEnter: (to, from, next) => {
            isNotLoginCheck(to, from, next);
        }
    }
];

const routes = Object.keys(routeComponentMapping).map(path => ({
    path, // 매핑 객체에서 정의된 경로
    component: () => import(routeComponentMapping[path]) // 해당 경로에 매핑된 컴포넌트를 동적으로 로드
}));

routes.push(...staticRoutes);

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;
