<template>
    <form name="form_chk" id="form_chk" method="post">
        <input type="hidden" id="m" name="m" value="service"/>
        <input type="hidden" id="token_version_id" name="token_version_id" :value="tokenVersionId"/>
        <input type="hidden" id="enc_data" name="enc_data" :value="encData"/>
        <input type="hidden" id="integrity_value" name="integrity_value" :value="integrityValue"/>
    </form>
    <form name="form_ipin" id="form_ipin">
        <input type="hidden" id="token_version_id" name="token_version_id" :value="ipinTokenVersionId"/>
        <input type="hidden" id="enc_data" name="enc_data" :value="ipinEncData"/>
        <input type="hidden" id="integrity_value" name="integrity_value" :value="ipinIntegrityValue"/>
    </form>
</template>

<script>
export default{
    data() {
        return {
            sEncData: '',
            ipinEncData: '',

            tokenVersionId: '',
            encData: '',
            integrityValue: '',
            ipinTokenVersionId: '',
            ipinEncData: '',
            ipinIntegrityValue: '',
            key: '',
            iv: '',
        }
    },
    methods: {
        // 본인인증 팝업
        async fnPopup(niceType) {
            console.log('running niceId: ' + niceType);
            this.$store.commit('getNiceType', niceType);
            this.$store.commit('getIpinType', '');
            var check = true;

            if(niceType == 'findPw'){
                var findId = document.querySelector('#memNiceFindId').value;

                
                if(findId == ''){
                    alert('아이디를 입력해주세요.');
                    return false;
                } 

                await this.axios.get(this.api.member.dupId, {
                    params: {
                        memId: findId
                    }
                })
                .then(res => {
                    const data = res.data;

                    if(data.result.check == 'N'){
                        this.$store.commit('getFindMemId', findId);
                        
                    }else{
                        alert('아이디가 존재하지 않습니다.');
                        check = false;
                    }
                    
                });

            }
            if(check){
                window.open('https://nice.checkplus.co.kr/CheckPlusSafeModel/service.cb', 'popupChk', 'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no');
                document.form_chk.action = "https://nice.checkplus.co.kr/CheckPlusSafeModel/service.cb";
                document.form_chk.target = "popupChk";
                document.form_chk.submit();
            }
        },

        // ipin 팝업
        async popIpinAuth(ipinType) {
            this.$store.commit('getIpinType', ipinType);
            this.$store.commit('getNiceType', '');
            var check = true;

            if(ipinType == 'findPw'){
                var findId = document.querySelector('#memIpinFindId').value;

                
                if(findId == ''){
                    alert('아이디를 입력해주세요.');
                    return false;
                } 

                await this.axios.get(this.api.member.dupId, {
                    params: {
                        memId: findId
                    }
                })
                .then(res => {
                    const data = res.data;

                    if(data.result.check == 'N'){
                        this.$store.commit('getFindMemId', findId);
                        
                    }else{
                        alert('아이디가 존재하지 않습니다.');
                        check = false;
                    }
                    
                });

            }
            if(check){
                window.open('', 'popupIPIN2', 'width=450,height=550,top=100,left=100,fullscreen=no,menubar=no,status=no,toolbar=no,titlebar=yes,location=no,scrollbar=no');
                document.form_ipin.action = "https://cert.vno.co.kr/ipin.cb";
                document.form_ipin.target = "popupIPIN2";
                document.form_ipin.submit();

            }
        },
        iosAlert(msg) {
            var ios = (/iphone|ipad|ipod/i.test(navigator.userAgent.toLowerCase()));
            if (ios) {
                alert(msg);
            }
        },

        // nice api 토큰 발급
        getAccessToken(type) {
            this.axios
                .get(this.api.token.getToken, {
                    params: {
                        productType: type
                    }
                })
                .then(res => {
                    const data = res.data
                    if(type == 'A'){
                        this.tokenVersionId = data.token_version_id
                        this.encData = data.enc_data
                        this.integrityValue = data.integrity
                    }else{
                        this.ipinTokenVersionId = data.token_version_id
                        this.ipinEncData = data.enc_data
                        this.ipinIntegrityValue = data.integrity
                    }
                    this.key = data.key
                    this.iv = data.iv
                    const result = {
                        key: this.key,
                        iv: this.iv
                    }
                    this.$nextTick(() => {
                        type == 'A' ? this.$store.commit('setNice', result) : this.$store.commit('setNiceIpin', result)
                    })
                    // setTimeout(() => {
                    //     alert(type + ': ' + JSON.stringify(result))
                    //     alert(type + ': ' + JSON.stringify(this.$store.state.memberStore.nice))
                    //     alert(type + ': ' + JSON.stringify(this.$store.state.memberStore.niceIpin))
                    // }, 1000);
                }).catch(err => {
                    console.error(err)
                })
        },
    },
    mounted() {
        this.getAccessToken('A')
        this.getAccessToken('B')
    },
}
</script>