<template>
    <div class="join-tab">
        <ul class="join-tab__list">
            <li class="join-tab__item active">
                <div class="tit">임시 본인인증</div>
            </li>
        </ul>
    </div>

    <div class="certification" v-if="!childChk">
        <ul class="contents login-box__list">
            <li class="login-box__item">
                <div class="icon"><img src="@/assets/resource/icon/login-icon01.png" alt="이미지"></div>
                <h3 class="tit" v-if="$store.state.memberStore.memType == 'D'">
                    14세 미만 어린이<br>본인 명의의 휴대폰 인증</h3>
                <h3 class="tit" v-else>본인 인증</h3>
                <p class="desc">본인명의 휴대폰</p>
                <div class="btn-wrap center">
                    <a href="javascript:;" class="btn blue" title="새 창 열림" @click="this.$refs.NiceIdVue.fnPopup('subChk')">
                        <span>인증하기</span>
                    </a>
                </div>
            </li>
            <li class="login-box__item">
                <div class="icon"><img src="@/assets/resource/icon/login-icon02.png" alt="이미지"></div>
                <h3 class="tit" v-if="$store.state.memberStore.memType == 'D'">
                    14세 미만 어린이 본인<br>아이핀 인증</h3>
                <h3 class="tit" v-else>아이핀 인증</h3>
                <p class="desc">아이핀 ID/PW</p>
                <div class="btn-wrap center">
                    <a href="javascript:;" class="btn blue" title="새 창 열림" @click="this.$refs.NiceIdVue.popIpinAuth('subChk')">
                        <span>인증하기</span>
                    </a>
                </div>
            </li>
        </ul>
    </div>

    <!-- <div class="contents info-text depth__wrap depth01">
        <div class="depth__bullet"><span class="bullet01"></span></div>
        <div class="depth__text">
            <h4 class="depth01__title title">유의사항</h4>
            <div class="depth__wrap depth02">
                <div class="depth__text">
                    <div class="depth__wrap depth03">
                        <div class="depth__text">
                            <div class="depth__wrap" v-if="$store.state.memberStore.memType == 'D'">
                                <div class="depth__bullet"><span class="bullet03"></span></div>
                                <div class="depth__text">
                                    <p>어린이 회원가입은 본인인증(휴대폰 또는 아이핀)과 법적대리인(보호자)인증 후 가입이 가능합니다.</p>
                                </div>
                            </div>
                            <div class="depth__wrap">
                                <div class="depth__bullet"><span class="bullet03"></span></div>
                                <div class="depth__text">
                                    <p>본인인증 방법 선택 후 팝업창이 나타나지 않으면 브라우저의 팝업차단을 해제해 주시기 바랍니다.</p>
                                </div>
                            </div>
                            <div class="depth__wrap">
                                <div class="depth__bullet"><span class="bullet03"></span></div>
                                <div class="depth__text">
                                    <p>아이핀(I-PIN) 인증 또는 휴대폰 인증시 장애가 있으신 경우는 나이스평가정보 실명확인 서비스 기관에 문의하시기 바랍니다. <br>
                                        나이스평가정보 본인 인증 콜센터 : 1600-1522</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <NiceIdVue ref="NiceIdVue"></NiceIdVue>
</template>

<script>
import NiceIdVue from '../util/NiceId.vue';
export default {
    components: {
        NiceIdVue
    },
    data() {
        return {
            // childChk: this.$store.state.memberStore.memType == 'D' ? true : false,
            certificationResult: null,
            isListenerRegistered: false,

            parentMemId: '',
            parentMemPwd: '',
            parentMemName: '',
        }
    },
    mounted() {
        if (!this.isListenerRegistered) {
            window.addEventListener('message', this.handleCertificationComplete);
            this.isListenerRegistered = true; // 등록 상태 업데이트
        }
    },
    beforeUnmount() {
        if (this.isListenerRegistered) {
            window.removeEventListener('message', this.handleCertificationComplete);
            this.isListenerRegistered = false; // 등록 상태 초기화
        }
    },
    methods: {
        handleCertificationComplete(event) {
            console.log("running handleCertification");
            // 메시지 타입 확인
            if (event.data.type === 'CERTIFICATION_COMPLETE') {
                console.log('받은 인증 결과2:', JSON.stringify(event.data));
                const ci = event.data.data.ci == null ? event.data.data.CI : event.data.data.ci
                console.log('ci: '+ ci);
                this.$store.commit('setMemCi',ci);
                
                this.$router.push('/');
            }
        },

        // 부모 인증
        chkParentInfo() {
            if(!this.$refs.parentAgree.checked) {
                alert('법정대리인(보호자) 확인 및 정보수집에 동의해주세요.');
                return
            }
            if(!this.parentMemId) {
                alert('법정대리인(보호자) 아이디를 입력해주세요.');
                return
            }
            if(!this.parentMemPwd) {
                alert('법정대리인(보호자) 비밀번호를 입력해주세요.');
                return
            }
            if(!this.parentMemName) {
                alert('법정대리인(보호자) 이름을 입력해주세요.');
                return
            }
            if (this.$refs.parentAgree.checked) {
                this.axios
                    .get(this.api.member.parentCheck, {
                        params: {
                            memName: this.parentMemName,
                            memId: this.parentMemId,
                            memPwdEnc: btoa(this.parentMemPwd)
                        }
                    })
                    .then(res => {
                        const isYn = res.data.isYn;
                        if (isYn == 'Y') {
                            const parent = {
                                instMemNo : res.data.instMemNo,
                                mphName : res.data.mphName,
                                mphHpNo : res.data.mphHpNo,
                                mphEmail : res.data.mphEmail,
                                mphBirth : res.data.mphBirth
                            }
                            this.$store.commit('setParent', parent);
                            this.childChk = false
                            alert('보호자 인증이 완료되었습니다.');
                        } else {
                            alert('인증에 실패하였습니다.\n다시 한번 시도해주시길 바랍니다.');
                        }
                    })
            }
        },
    },
}
</script>